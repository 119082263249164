import PropTypes from "prop-types";
import { Suspense } from "react";
import LoadingIndicator from "../components/indicator/LoadingIndicator";
import SuspenseLoader from "../components/indicator/SuspenseLoader";
import AntDesignProvider from "./AntDesignProvider";
import { AuthProvider } from "./AuthProvider";
import NotificationProvider from "./NotificationProvider";
import AppQueryClientProvider from "./QueryClientProvider";
import ReduxProvider from "./ReduxProvider";

export default function AppProvider(props) {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <ReduxProvider>
        <AppQueryClientProvider>
          <AuthProvider>
            <AntDesignProvider>
              <NotificationProvider>
                <LoadingIndicator>{props.children}</LoadingIndicator>
              </NotificationProvider>
            </AntDesignProvider>
          </AuthProvider>
        </AppQueryClientProvider>
      </ReduxProvider>
    </Suspense>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node,
};
