import { Button, Form, Select, Skeleton, Spin } from "antd";
import { useFetchInitialEvaluationQuestions } from "common/hooks/queries";
import EvaluationQuestionsDisplay from "components/assessment/InitialAssessmentDisplay";
import { dispatchNotification, getQuestionErrors } from "helper";
import { last } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { submitInitialEvaluationAnswers } from "services/ApiService";
import "./styles.css";
import { useActions } from "common/hooks";

export const InitialEvaluationPage = (props) => {
  const { appointmentId } = props;
  const { data, isLoading } = useFetchInitialEvaluationQuestions(appointmentId);
  const { tabs, sections } = data?.evaluationQuestions || {};
  const [evaluationForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { displayNotification } = useActions();

  const tabNames = useMemo(() => (tabs ? Object?.keys(tabs) : []), [tabs]);

  const [currentTab, setCurrentTab] = useState("");

  const isFinalTab = last(tabNames) === currentTab;

  const filteredSections = sections?.filter((sec) =>
    tabs[currentTab]?.includes(sec?.sectionNumber)
  );

  useEffect(() => {
    if (tabNames && !currentTab) {
      setCurrentTab(tabNames?.[0]);
    }
  }, [tabNames]);

  const formatValues = useCallback((data) => {
    const formatted = [];
    Object.entries(data).map(([key, value]) => {
      const answer = data[key] ? [data[key]] : [];
      formatted.push({
        questionNumber: key,
        answers: answer,
      });
    });
    return formatted;
  }, []);

  const handleNextNavigation = () => {
    const tabIndex = tabNames.findIndex((tab) => tab === currentTab);
    if (isFinalTab) return;
    const nextTab = tabNames[tabIndex + 1];
    setCurrentTab(nextTab);
  };

  const handleFormUpdate = async (values) => {
    setLoading(true);
    const sectionsAndQuestions = filteredSections.map((s) => ({
      id: s.sectionNumber,
      questions: s.questions.map((q) => q.questionNumber),
    }));
    const formattedValues = formatValues(values);
    const payload = [];
    sectionsAndQuestions.forEach((section) => {
      const values = formattedValues.filter((f) =>
        section?.questions?.includes(+f.questionNumber)
      );
      payload.push({
        sectionNumber: section?.id,
        response: values,
        finalSubmission: isFinalTab,
      });
    });

    try {
      const response = await submitInitialEvaluationAnswers(
        payload,
        appointmentId
      );
      dispatchNotification("success", `${currentTab} has been saved`);
      setLoading(false);
      handleNextNavigation();
    } catch (error) {
      dispatchNotification(
        "error",
        `${currentTab} has not been saved`,
        "Please try again"
      );
      setLoading(false);
    }
  };

  const handleFormSubmission = () => {
    evaluationForm.validateFields().then(
      (values) => {
        handleFormUpdate(values);
      },
      (error) => {
        const errors = getQuestionErrors(error);
        displayNotification({
          type: "error",
          message: `${errors?.join(" , ")} ${errors?.length > 1 ? "are" : "is"} required`,
          description: "Please fill the mandatory fields",
        });
      }
    );
  };

  const handleSectionChange = (id) => {
    setCurrentTab(id);
  };

  const tabOptions = useMemo(
    () =>
      tabNames.map((key, index) => ({
        label: `${key}`,
        value: `${key}`,
        id: "section-select",
      })),
    [tabs, currentTab]
  );

  return (
    <Skeleton loading={isLoading} active>
      <section className="flex flex-col xl:flex-row flex-wrap gap-4 min-h-[25.75] pt-[6.75rem]">
        <div>
          <Select
            id="section"
            className="text-white h-10 section-select w-[12rem]"
            value={currentTab}
            onChange={handleSectionChange}
            options={tabOptions}
          />
        </div>
      </section>

      <section>
        <section className="pt-[3.375rem]">
          <Spin spinning={loading} tip="Saving Response">
            <EvaluationQuestionsDisplay
              form={evaluationForm}
              sections={filteredSections}
            />
          </Spin>
        </section>
      </section>

      <section className="flex justify-end gap-4 text-base font-medium pt-12">
        <Button
          htmlType="button"
          loading={loading}
          onClick={() => handleFormSubmission()}
          style={{
            background: "linear-gradient(270deg, #2E37A4 44.5%, #4E57CD 100%)",
          }}
          className="rounded-lg min-w-[11rem] h-10 text-white border-none"
        >
          {isFinalTab ? "Submit" : "Save"}
        </Button>
      </section>
    </Skeleton>
  );
};
