import { useQuery } from "@tanstack/react-query";
import { fetchAppointmentListForTherapist } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchAppointmentList = (therapistId, pageLimit, pageNumber) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_APPOINTMENT_LIST,therapistId,pageLimit,pageNumber],
    queryFn: () => fetchAppointmentListForTherapist(therapistId, pageLimit,pageNumber),
    enabled: !!therapistId,
  });
