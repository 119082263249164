import { useQuery } from "@tanstack/react-query";
import {
    getTreatmentPlan
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchTreatmentPlan = (appointmentId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_TREATMENT_PLAN, appointmentId],
    queryFn: () => getTreatmentPlan(appointmentId),
    enabled: !!appointmentId,
  });
