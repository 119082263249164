import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  signInWithCredential ,
} from "firebase/auth";
import { dispatchNotification } from "./StoreHelper";
import { Test_Therapist_Id } from "common/constants";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const checkifEmailVerified = () => {
  const auth = getAuth();
  return auth?.currentUser?.emailVerified;
};

export const getFirebaseAuth = () => {
  const app = initializeApp(firebaseConfig);
  return getAuth(app);
};

// Get ID token to append on Authorization Headers
export const getBearerToken = async () => {
  const auth = getAuth();
  if (!auth?.currentUser?.emailVerified) return "";
  try {
    const token = await auth?.currentUser?.getIdToken();
    return token;
  } catch (error) {
    console.log(error);
  }
};

export const signInUsingEmailAddress = (emailAddress, queryParams) => {
  const auth = getFirebaseAuth();
  const continueUrl = queryParams? `${process.env.REACT_APP_REDIRECT_URI}${queryParams}`: `${process.env.REACT_APP_REDIRECT_URI}`
  const actionCodeSettings = {
    url: continueUrl, // Same URL where the app is hosted
    handleCodeInApp: true,
  };
  return new Promise((resolve) => {
    sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
      .then(async function () {
        resolve();
        window.localStorage.setItem("emailForSignIn", emailAddress);
      })
      .catch(function (error) {
        console.log(error);
        dispatchNotification(
          "error",
          error?.code,
          "Please contact the adminitstrator"
        );
      });
  });
};

export const googleSignUp = async () => {
  try {
    const auth = getFirebaseAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result?.user;
    const fullName = user?.displayName;
    const [firstName, lastName] = fullName ? fullName?.split(" ") : ["", ""];
    const requestPayload = {
      email: user?.email,
      firstName: firstName,
      lastName: lastName,
      role: "doctor",
      photoURL: user?.photoURL,
      mobile: user?.phoneNumber,
    };
    const response = await googleSignin(requestPayload);
    if (response?.status >= 200 && response?.status < 300) {
      // Handle successful login
      const data = response?.data;
      dispatchNotification("success", "Signed us succcessfully");
      return data;
    } else {
      // Handle errors based on status code
      const errorMessage =
        response.data.message || "Login failed. Please check your credentials.";
      dispatchNotification("error", response?.data?.message);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const completeSignInWithGoogle = async (accessToken) => {
  try {
    const auth = getFirebaseAuth();
    // Create a credential with the access token
    const credential = GoogleAuthProvider.credential(accessToken);
    // Sign in with the credential
    await signInWithCredential(auth, credential);
  } catch (error) {
    console.error('Error signing in with Google:', error);
  }
};

export const handleLogout = () => {
  const auth = getFirebaseAuth();
  if (!auth?.currentUser?.emailVerified) return;
  auth?.signOut().then(() => {
    window.localStorage.clear();
    window.localStorage.clear();
    window.location.reload();
  });
};
