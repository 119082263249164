import { Route_Url, Test_Therapist_Id } from "common/constants";
import { getAuth } from "firebase/auth";
import { isClient, isClientRoute, isTherapist, isTherapistRoute } from "helper";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getUser } from "store/selectors";

const RouteListenerLayout = ({ children }) => {
  const { pathname } = useLocation();
  const user = useSelector(getUser);

  const loggedInAsClient = isClient(user);
  const loggedInAsTherapist = isTherapist(user);

  const clientRoute = isClientRoute(pathname);
  const therapistRoute = isTherapistRoute(pathname);


  if (loggedInAsClient && therapistRoute && !children) {
    return <Navigate to={`${Route_Url.PatientDashboard}`} />;
  }

  if (loggedInAsTherapist && clientRoute && !children) {
    return <Navigate to={`${Route_Url.DoctorDashboard}`} />;
  }

  return children ?? <Outlet />;
};

export default RouteListenerLayout;
