import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  results: []
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.results = action.payload;
    },
    addEvent: (state, action) => {
      const filtered = state.results.filter(rs => !rs.isNew);
      state.results = [...filtered, action.payload]
    },
  },
});

// Action creators are generated for each case reducer function
export const calendarActions = calendarSlice.actions;

export const reducer = calendarSlice.reducer;
