import { useQuery } from "@tanstack/react-query";
import {
    getSubscriptions
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchSubscriptionPlans = () =>
  useQuery({
    queryKey: [AppQueriKeys.GET_SUBSCRIPTION_PLAN],
    queryFn: () => getSubscriptions(),
  });
