import React from "react";
import { Button, Form } from "antd";
import DynamicFormField from "components/appoinments/DynamicFormField";
import { getQuestionErrors } from "helper";
import { useActions } from "common/hooks";

export default function InitialAssessmentDisplay(props) {
  const { sections, form } = props;

  

  return (
    <section>
      {/* Radio Section */}
      <Form
        layout="vertical"
        form={form}
        preserve
        scrollToFirstError
        validateTrigger="onChange"
      >
        <section>
          {sections.map((section) => (
            <section
              key={section?.sectionNumber}
              className="flex flex-row flex-wrap gap-2"
            >
              <header className="text-lg font-medium basis-full pb-4">
                {section?.name}
              </header>
              {section?.questions.map((question) => (
                <section
                  key={question?.questionNumber}
                  className="basis-full mx-10"
                >
                  <div
                    className="flex flex-col w-full"
                    key={`${section?.sectionNumber}-${question?.questionNumber}`}
                  >
                    <p className="text-base font-normal pb-1.5">
                      {question?.questionNumber}. {question?.text}{" "}
                      {question?.isMandatory && (
                        <span className="text-red-600"> *</span>
                      )}
                    </p>

                    <DynamicFormField
                      {...question}
                      sectionId={section?.sectionNumber}
                    />
                  </div>
                </section>
              ))}
            </section>
          ))}
        </section>
    
      </Form>
    </section>
  );
}
