import { Button, Skeleton } from "antd";
import { useFetchSubscriptionPlans } from "common/hooks/queries";
import { check_purple, check_purple_overlap } from "components/imagepath";
import { useCallback } from "react";

export default function SubscriptionPlan(props) {
  const { data: plans, isLoading } = useFetchSubscriptionPlans();

  const { planSelected, selectedPlan } = props;

  const isSelectedPlan = useCallback(
    (plan) => {
      return selectedPlan === plan.id;
    },
    [selectedPlan]
  );

  return (
    <Skeleton loading={isLoading} active>
      <section className="bg-white p-10 pb-4 flex flex-row flex-wrap justify-between items-center gap-4">
        {plans?.length > 0 &&
          plans?.map((plan) => (
            <section
              key={plan.type}
              className={`${isSelectedPlan(plan) ? "bg-primary-dark text-white" : "bg-primary-10 text-black"} basis-[25%] h-[31.875rem] pt-14 pl-10 pr-[5.375rem] rounded-3xl flex-col justify-center relative`}
            >
              {plan?.isPopular && (
                <div className="absolute right-9 top-7 flex justify-center shadow-md items-center h-7 w-[7.5rem] bg-white text-primary-40 font-extrabold text-xs rounded-md">
                  MOST POPULAR
                </div>
              )}

              <div>
                <span
                  className={`${isSelectedPlan(plan) ? "text-white" : "text-black"} text-4xl  font-bold leading-[2.875rem] pb-5 pr-0.5`}
                >
                  {plan?.price}
                </span>
                / {plan?.billCycle}
                <header
                  className={`${isSelectedPlan(plan) ? "text-white" : "text-dark-60"}  text-[1.75rem] font-medium pb-1.5`}
                >
                  <h5>{plan.name}</h5>
                </header>
                <p
                  className={`${isSelectedPlan(plan) ? "text-white" : "text-dark-60"} 
                  text-base font-medium text-gray-textColor pb-2.5 h-[8rem]`}
                >
                  {plan?.description}
                </p>
                <ul className="list-none pl-0 pb-[5.5rem]">
                  <li className="flex flex-row">
                    <img
                      src={
                        isSelectedPlan(plan)
                          ? check_purple_overlap
                          : check_purple
                      }
                      className="w-5 h-5 mr-3 mb-2 inline-block"
                    />
                    {plan.features?.maxUsersCount} users
                  </li>
                  <li className="flex flex-row">
                    <img
                      src={
                        isSelectedPlan(plan)
                          ? check_purple_overlap
                          : check_purple
                      }
                      className="w-5 h-5 mr-3 mb-2 inline-block"
                    />
                    {plan.features?.maxAppointmentsCount} appointments
                  </li>
                </ul>
                <Button
                  onClick={() => planSelected(plan.id)}
                  className={`${
                    isSelectedPlan(plan)
                      ? "bg-white text-primary-dark"
                      : "bg-primary-dark text-white"
                  }
                    rounded-xl h-11 w-[14.5rem] flex justify-center font-medium items-center  mb-[4rem]`}
                >
                  Choose Plan
                </Button>
              </div>
            </section>
          ))}
      </section>
    </Skeleton>
  );
}
