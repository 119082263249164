import { Typography } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
export default function SuspenseLoader() {
  const loadingText = (
    <Typography.Title level={4} editable={false} className="pt-3">
      Loading pages in a bit
    </Typography.Title>
  );

  return (
    <div className="position-relative vh-100 bg-opacity-10 d-flex justify-content-center align-content-center bg-secondary">
      <div className="position-absolute top-50 left-50 text-center">
        <LoadingOutlined className="" style={{ fontSize: 48 }} spin />

        {loadingText}
      </div>
    </div>
  );
}
