import { Route_Url, Test_Therapist_Id } from "common/constants";
import { checkifEmailVerified } from "helper";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getUser } from "store/selectors";

export default function RoleRedirect() {
  const user = useSelector(getUser);
  const isEmailVerified = checkifEmailVerified();
 
  if (!isEmailVerified) {
    return (
      <Navigate to={Route_Url.Login} />
    );
  }

  if (user && user?.role?.toLowerCase() === "client") {
    return <Navigate to={`${Route_Url.PatientDashboard}`} />;
  }

  if (user && user?.role?.toLowerCase() === "therapist") {
    return <Navigate to={`${Route_Url.DoctorDashboard}`} />;
  }

  return (
    <Navigate to={`${Route_Url.Login}/${Test_Therapist_Id}`} />
  );
}
