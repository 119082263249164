import { DatePicker, Typography } from "antd";
import { DATE_FORMATS } from "common/constants";
import { dayjs, noEmptySpaces } from "helper";

const { Text } = Typography;

export default function AppointmentForm({ form, isReadonly, values }) {
  const { register, formState } = form;

  const { errors } = formState;

  const splitClass = !!isReadonly ? "basis-[47%]" : "basis-full";

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().subtract(18, "years");
  };

  const handleDateChange = (value) => {
    if (!!isReadonly) return null;
    form?.setValue("dob", value);
  };

  return (
    <form noValidate className="flex flex-row justify-between flex-wrap">
      <div className={splitClass}>
        <div className="form-group local-forms">
          <label htmlFor="firstName">
            First Name <span className="login-danger">*</span>
          </label>
          <input
            {...register("firstName", {
              required: "Firstname is required",
              minLength: {
                value: 2,
                message: "Firstname should contain atleast 2 characters",
              },
              maxLength: {
                value: 50,
                message: "Firstname should not exceed 50 characters",
              },
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Should contain only alphabets",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            readOnly={!!isReadonly}
            style={{
              borderColor: errors?.firstName?.type ? "red" : "",
            }}
          />
          {errors?.firstName && (
            <Text type="danger " className="pl-2">
              {errors?.firstName?.message}
            </Text>
          )}
        </div>
      </div>
      {/* Last Name */}
      <div className={splitClass}>
        <div className="form-group local-forms">
          <label>
            Last Name <span className="login-danger">*</span>
          </label>
          <input
            {...register("lastName", {
              required: "Last name is required",
              minLength: {
                value: 2,
                message: "Last name should contain atleast 2 characters",
              },
              maxLength: {
                value: 50,
                message: "Last name should not exceed 50 characters",
              },
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Should contain only alphabets",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            readOnly={!!isReadonly}
            style={{
              borderColor: errors?.lastName?.type ? "red" : "",
            }}
            aria-invalid={errors?.lastName ? "true" : "false"}
          />
          {errors?.lastName?.type && (
            <Text type="danger" className="pl-2">
              {errors?.lastName?.message}
            </Text>
          )}
        </div>
      </div>

      {/* Gender */}
      <div className="basis-full pb-3">
        <div className="form-group select-gender">
          <label className="gen-label">
            Gender<span className="login-danger">*</span>
          </label>
          <div className="form-check-inline">
            <label htmlFor="gender" className="form-check-label">
              <input
                {...register("gender", { required: true })}
                type="radio"
                value={"male"}
                name="gender"
                id="gender"
                disabled={!!isReadonly}
                className="form-check-input"
              />
              Male
            </label>
          </div>
          <div className="form-check-inline">
            <label htmlFor="gender" className="form-check-label">
              <input
                {...register("gender", { required: true })}
                type="radio"
                name="gender"
                id="gender"
                value={"female"}
                disabled={!!isReadonly}
                className="form-check-input"
              />
              Female
            </label>
          </div>
          <div className="form-check-inline">
            <label htmlFor="gender" className="form-check-label">
              <input
                {...register("gender", { required: true })}
                type="radio"
                name="gender"
                id="gender"
                value={"others"}
                disabled={!!isReadonly}
                className="form-check-input"
              />
              Others
            </label>
          </div>
          {errors?.gender?.type && (
            <Text type="danger " className="pl-2 block">
              Gender is required
            </Text>
          )}
        </div>
      </div>

      {/* Date Of Birth */}
      <div className={splitClass}>
        <div className="form-group local-forms mb-6">
          <label>
            Date of Birth <span className="login-danger">*</span>
          </label>
          <DatePicker
            {...register("dob", { required: true })}
            className="form-control datetimepicker"
            format={DATE_FORMATS["MM/DD/YYYY"]}
            onChange={handleDateChange}
            defaultValue={!!isReadonly ? values?.dob : null}
            showToday={false}
            allowClear={false}
            inputreadOnly={!!isReadonly}
            style={{
              borderColor: errors?.dob?.type ? "red" : "",
            }}
            aria-invalid={errors?.dob ? "true" : "false"}
          />

          {errors?.dob?.type && (
            <Text type="danger " className="pl-2">
              Date of birth is required
            </Text>
          )}
        </div>
      </div>

      <div className={splitClass}>
        <div className="form-group local-forms">
          <label>
            Phone Number<span className="login-danger">*</span>
          </label>

          <input
            {...register("mobile", {
              required: "Mobile number is required",
              validate: noEmptySpaces,
              maxLength: {
                value: 20,
                message: "Mobile number should not exceed 20 digits",
              },
              minLength: {
                value: 10,
                message: "Mobile number should have 10 digits",
              },
              pattern: {
                value: /^[\d\+\-\(\)\s]+$/,
                message: "Invalid number",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            min={0}
            required
            style={{
              borderColor: errors?.mobile?.type ? "red" : "",
            }}
            aria-invalid={errors?.mobile ? "true" : "false"}
          />

          {errors?.mobile?.type && (
            <Text type="danger " className="pl-2">
              {errors?.mobile?.message}
            </Text>
          )}
        </div>
      </div>

      {/* Email */}
      <div className={splitClass}>
        <div className="form-group local-forms">
          <label>
            Email <span className="login-danger">*</span>
          </label>
          <input
            {...register("email", {
              required: "Email is required",
              validate: noEmptySpaces,
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "Email address is not valid",
              },
            })}
            className="form-control"
            type="email"
            placeholder=""
            readOnly={!!isReadonly}
            style={{
              borderColor: errors?.email?.type ? "red" : "",
            }}
            aria-invalid={errors?.email ? "true" : "false"}
            required
          />
          {errors?.email && (
            <Text type="danger " className="pl-2">
              {errors.email?.message}
            </Text>
          )}
        </div>
      </div>

      {/* Last 4 digits of SSN */}
      <div className={splitClass}>
        <div className="form-group local-forms mb-6">
          <label>
            Last 4 digits of SSN <span className="login-danger">*</span>
          </label>
          <input
            {...register("ssn", {
              required: "SSN is required",
              maxLength: {
                value: 4,
                message: "SSN should be 4 digits",
              },
              minLength: {
                value: 4,
                message: "SSN should be 4 digits",
              },
              pattern: {
                value: /^\d+$/,
                message: "Invalid SSN",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            min={0}
            readOnly={!!isReadonly}
            required
            style={{
              borderColor: errors?.ssn?.type ? "red" : "",
            }}
            aria-invalid={errors?.ssn ? "true" : "false"}
          />

          {errors?.ssn?.type && (
            <Text type="danger " className="pl-2">
              {errors?.ssn?.message}
            </Text>
          )}
        </div>
      </div>

      <div className="basis-full">
        <div className="form-group local-forms">
          <label>
            Address <span className="login-danger">*</span>
          </label>
          <textarea
            {...register("address", {
              required: "Address is required",
              validate: noEmptySpaces,
              maxLength: {
                value: 300,
                message:"Only 300 characters are allowed"
              }
            })}
            className="form-control"
            rows={0}
            cols={0}
            readOnly={!!isReadonly}
            defaultValue={""}
            style={{
              borderColor: errors?.address?.type ? "red" : "",
            }}
            aria-invalid={errors?.address ? "true" : "false"}
            required
          />
          {errors?.address?.type && (
            <Text type="danger " className="pl-2">
              {errors.address.message}
            </Text>
          )}
        </div>
      </div>
      <div className="basis-full">
        <div className="form-group local-forms mb-0">
          <label>
            Notes <span className="login-danger">*</span>
          </label>
          <textarea
            {...register("notes", {
              required: "Notes is required",
              validate: noEmptySpaces,
              maxLength: {
                value: 300,
                message:"Only 300 characters are allowed"
              }
            })}
            className="form-control"
            rows={3}
            cols={30}
            readOnly={!!isReadonly}
            defaultValue={null}
            style={{
              borderColor: errors?.notes ? "red" : "",
            }}
            aria-invalid={errors?.notes ? "true" : "false"}
            required
          />
          {errors?.notes?.type && (
            <Text type="danger " className="pl-2">
              {errors?.notes?.message}
            </Text>
          )}
        </div>
      </div>
    </form>
  );
}
