import Axios from "axios";
import { getBearerToken } from "./AuthHelper";
import { getCurrentTimeZone } from "./index";


const AxiosFactory = new Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
});

// Add a request interceptor
AxiosFactory.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    config.headers["Authorization"] = 'Bearer ' + await getBearerToken();
    config.headers["timeZone"] =  getCurrentTimeZone();
    return config;
  },
  function (error) {
    // Do something with request error
    console.log("error");

    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosFactory.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // dispatchNotification something with response error
    return Promise.reject(error);
  }
);

export { AxiosFactory };

