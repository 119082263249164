import { configureStore } from "@reduxjs/toolkit";
import {
  calendarReducer,
  loaderReducer,
  notificationReducer,
  userReducer,
} from "./slicers";

export const store = configureStore({
  reducer: {
    progress: loaderReducer,
    notification: notificationReducer,
    user: userReducer,
    calendar: calendarReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const getProgressCount = () => store.getState()?.progress.value;

export const getNotificationDetails = () => store.getState()?.notification;

