import { Button, Divider, Form, Select } from "antd";
import DynamicFormField from "components/appoinments/DynamicFormField";
import { useMemo, useState } from "react";
import "./styles.css";

export default function AssessmentQuestionsDisplay(props) {
  const { sectionDetails, form } = props;

  const questions = sectionDetails?.questions || [];
  return (
    <section className="min-w-[50vw]">
      <Divider className="mb-8" />
      {/* Radio Section */}
      <Form
        name={sectionDetails?.name}
        key={sectionDetails?.sectionNumber}
        layout="vertical"
        form={form}
        preserve
        scrollToFirstError
        validateTrigger="onChange"
      >
        <section className="flex flex-row flex-wrap gap-2">
          {questions.map((question) => (
            <section
              key={question?.questionNumber}
              className={`${question.type === "text" ? "basis-[45%]" : "basis-full"}`}
            >
              <div className="flex flex-col w-full">
                <p className="text-base font-medium mb-1">
                  {question?.questionNumber}. {question.text}
                  {question?.isMandatory && (
                    <span className="text-red-600">*</span>
                  )}
                </p>
                <DynamicFormField
                  {...question}
                  sectionId={sectionDetails?.sectionNumber}
                />
              </div>
            </section>
          ))}
        </section>
      </Form>
    </section>
  );
}
