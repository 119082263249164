import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  message: "",
  description: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.message = action.payload.message;
      state.description = action.payload.description;
      state.type = action.payload.type;
    },
    clearNotification: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const notificationActions = notificationSlice.actions;

export const { reducer } = notificationSlice;
