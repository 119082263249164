export const AppQueriKeys = {
  GET_ASSESSMENT_QUESTIONS: "getAssessmentQuestions",
  GET_ASSESSMENT_ANSWERS: "getAssessmentAnswers",
  GET_APPOINTMENT_LIST: "getAppointmentlist",
  GET_APPOINTMENT_DETAILS: "getAppointmentDetails",
  GET_SUBSCRIPTION_PLAN: "getSubscriptionPlan",  
  GET_INITIAL_EVALUATION_QUESTIONS: "getInitialEvaluationQuestions",
  GET_TREATMENT_PLAN: "getTreatmentPlan",
  GET_USER_BY_ID: "getUserById",

};
