import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import dayjs from "dayjs";
import { formatDateString } from "common/constants";

dayjs.extend(utc);
dayjs.extend(timezone);

// Set the default timezone
const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Change to your desired time zone
dayjs.tz.setDefault(defaultTimeZone);

const getCurrentTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const combineDateTime = (dateString, timeString, durationInMinutes = null) => {
  // create a date object with a specific date
  const date = dayjs(dateString);
  // create a time object with a specific time
  const time = dayjs(timeString, "HH:mm:ss");

  // combine the date and time objects
  const dateTime = date
    .set("hour", time.hour())
    .set("minute", time.minute())
    .set("second", time.second());
  return dateTime.add(durationInMinutes, "minutes").toISOString();
};

const convertDateToISO = (date) => {
  if(!date) return "";
  if (typeof date === "object" && checkIsValidDateObj(date)) {
    return date?.toISOString();
  }

  if (typeof date === "string") {
    return dayjs(date).toISOString();
  }
};

const checkIsValidDateObj = (obj) => dayjs.isDayjs(obj);
export {
  dayjs,
  defaultTimeZone,
  combineDateTime,
  checkIsValidDateObj,
  getCurrentTimeZone,
  convertDateToISO
};
