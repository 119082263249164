import { useDispatch,  } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "store/action-creators";

/**
 * Get combined actions based with wrapped dispatch
 * @returns {  string}
 */
export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(actionCreators, dispatch);
};