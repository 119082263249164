import { API_ROUTES } from "common/constants";
import { AxiosFactory, getBearerToken } from "helper";

const axios = AxiosFactory;

/**
 * Get User details
 * @returns
 */
const verifyUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getBearerToken();
      const response = await axios.post(API_ROUTES.PATIENT.verifyUser, {
        token,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get User By Email
 * @returns
 */
const getUserbyEmail = async (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getUser}?email=${email}`,
        {
          token,
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get Calendar Bookings
 * @returns
 */
const getCalendarBookings = async (therapistId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getCalendarBookings}?therapist=${therapistId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Create Appointment
 * @returns
 */
const bookAppointment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.bookAppointment}`,
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Fetch initial Assessment Questions
 * @returns
 */
const fetchInitialAssessmentQuestions = async (appointmentId, lang) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.PATIENT.getAssessmentQuestions}?appointmentId=${appointmentId}&language=${lang}`
      );
      resolve(response?.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Submit  initial Assessment Answers
 * @returns
 */
const submitAssessmentAnswers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.submitQuestions}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get initial Assessment Answers By Section
 * @returns
 */
const fetchAnswersForInitialAssessment = async (
  appointmentId,
  sectionNumber,
  lang = "en"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.PATIENT.getAssessmentSavedResponse}?appointmentId=${appointmentId}&language=${lang}&sectionNumber=${sectionNumber}`,
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Fetch the appointment list for the therapist
 * @returns
 */
const fetchAppointmentListForTherapist = async (
  therapistId,
  pageLimit = 10,
  pageNumber = 1
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getAppointmentList}?&limit=${pageLimit}&page=${pageNumber}&sortBy=appointmentStartTimestamp:asc&gt=${new Date().toISOString()}&timestampField=appointmentStartTimestamp`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Fetch the appointment details by id
 * @returns
 */
const fetchAppointmentDetailsById = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getAppointmentDetails}?id=${appointmentId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Fetch the appointment details by id
 * @returns
 */
const updateAppointmentStatus = async (payload, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.updateAppointmentStatus}?id=${appointmentId}`,
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Fetch the subscriptions plans
 * @returns
 */
const getSubscriptions = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_ROUTES.DOCTOR.getSubscriptions}`);
      resolve(response.data?.results);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Create new user for therapist
 * @returns
 */
const createUserAccount = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.createuser}`,
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get Initial Evaluation Questions
 * @returns
 */
const getInitialEvaluationQuestions = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getInitialEvaluationPlan}?appointmentId=${appointmentId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get Initial Evaluation Questions
 * @returns
 */
const getTreatmentPlan = async (appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getTreatmentPlan}?appointmentId=${appointmentId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get User By id
 * @returns
 */
const getUserById = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API_ROUTES.DOCTOR.getUser}/${userId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};


/**
 * Submit  initial Assessment Answers
 * @returns
 */
const submitInitialEvaluationAnswers = async (data, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.submitInitialEvaluationAnswers}?appointmentId=${appointmentId}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Submit  initial Assessment Answers
 * @returns
 */
const submitTreatmentPlanAnswers = async (data, appointmentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${API_ROUTES.DOCTOR.submitTreatmentPlan}?appointmentId=${appointmentId}`,
        data
      );
      resolve(response?.data);
    } catch (error) {
      reject(error);
    }
  });
};


export {
  verifyUser,
  getUserbyEmail,
  getCalendarBookings,
  bookAppointment,
  fetchInitialAssessmentQuestions,
  submitAssessmentAnswers,
  fetchAnswersForInitialAssessment,
  fetchAppointmentListForTherapist,
  fetchAppointmentDetailsById,
  updateAppointmentStatus,
  getSubscriptions,
  createUserAccount,
  getInitialEvaluationQuestions,
  getUserById,
  submitInitialEvaluationAnswers,
  getTreatmentPlan,
  submitTreatmentPlanAnswers,
};
