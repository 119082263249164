import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const progressActions = progressSlice.actions;

export const { reducer } = progressSlice;
