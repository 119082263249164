/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useQueryClient } from "@tanstack/react-query";
import { Button, Checkbox, Flex, Modal, Progress, Skeleton, Tabs, Tooltip } from "antd";
import { AppQueriKeys } from "common/hooks/queries/AppQueries";
import FeatherIcon from "feather-icons-react";
import { dispatchNotification } from "helper";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { updateAppointmentStatus } from "services/ApiService";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import "./EditAppointment.css";
import { InitialAssessmentTab } from "./Tabs/initialAssessment";
import { InitialEvaluationPage } from "./Tabs/initialEvaluation";
import { TreatmentPlanTab } from "./Tabs/treatmentPlan";
import { useFetchAppointmentDetailsById } from "common/hooks/queries";

const EditAppoinments = () => {
  const params = useParams();
  const appointmentId = params?.appointmentId;
  const [loading, setLoading] = useState(false);
  const {
    data,
    isLoading,
    refetch: refetchInitialAssessment,
  } = useFetchAppointmentDetailsById(appointmentId);
  const [isFree, setIsFree] = useState(false);

  const [confirmation, setConfirmation] = useState({
    show: false,
    type: "",
  });
  const scores = data?.scores;

  const handleAccept = () => {
    setConfirmation({
      show: true,
      type: "accept",
    });
  };

  const handleReject = () => {
    setConfirmation({
      show: true,
      type: "reschedule",
    });
  };

  const handleDialogCancel = () => {
    setConfirmation({
      show: false,
      type: null,
    });
    setIsFree(false);
  };

  const handleConfirmation = async () => {
    setLoading(true);
    const payload = {
      accepted: confirmation.type === "accept",
      allowReschedule: true,
      freeReschedule: isFree,
    };

    try {
      const response = await updateAppointmentStatus(payload, appointmentId);
      dispatchNotification(
        "success",
        "Status modifed",
        "Appointment Status updated successfully"
      );
      setConfirmation({
        show: false,
        type: "",
      });
      refetchInitialAssessment();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const OperationsSlot = useMemo(
    () => ({
      left: null,
      right: (
        <>
          <div className="flex flex-row gap-2 text-base font-medium">
            <Button
              className="bg-gray-20 text-gray-30 rounded-lg h-10 w-[6.5rem]"
              onClick={handleReject}
            >
              Reschedule
            </Button>
          </div>
        </>
      ),
    }),
    []
  );

  const TabItems = [
    {
      key: "assessmentInfo",
      label: "Initial Assessment",
      key: "info",
      children: (
        <div className="mt-6">
          <InitialAssessmentTab appointmentId={appointmentId} />
        </div>
      ),
    },
    {
      key: "initialEvaluation",
      label: "Initial Evaluation",
      children: <InitialEvaluationPage appointmentId={appointmentId} />,
    },
    {
      key: "treatment",
      label: "Treatment Plan",
      children: <TreatmentPlanTab appointmentId={appointmentId} />,
    },
    {
      key: "followup",
      label: "Follow up Evaluation",
    },
    {
      key: "reports",
      label: "Reports",
    },
  ];

  const StrokeColors = ["#1677FF", "#F5222D", "#189F4E", "#FA8C16"];

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="edit-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Appointment</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="m-5 flex flex-row flex-wrap justify-between items-center">
                    <Skeleton loading={isLoading} active>
                    {scores?.map((score, index) => (
                      <div className="flex-col basis-[20%]">
                        <span className="text-sm font-medium text-gray-textColor whitespace-nowrap">
                          <span className="uppercase">{score.type} </span> - {score?.value}
                        </span>
                        <Tooltip title={score?.score}>
                        <Progress
                          percent={score.percentage}
                          size="default"
                          steps={10}
                          showInfo
                          trailColor="#D9D9D9"
                          type="line"
                          status="normal"
                          strokeWidth={10}
                          className="block w-full"
                          strokeColor={StrokeColors[index]}
                        />
                        </Tooltip>
                      </div>
                    ))}
                    </Skeleton>
                  </div>
                  <div className="card-body flex min-h-screen">
                    <Tabs
                      tabBarExtraContent={OperationsSlot}
                      defaultActiveKey="1"
                      className="min-w-full"
                      type="card"
                      size="large"
                      id="editappointment"
                      items={TabItems}
                      destroyInactiveTabPane
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
      </>
      <Modal
        open={confirmation?.show}
        closable={false}
        onCancel={handleDialogCancel}
        onOk={handleConfirmation}
        confirmLoading={loading}
        maskClosable={false}
        destroyOnClose
        style={{ width: 600 }}
      >
        <div className="text-base font-medium">
          Are you sure want to {confirmation?.type} the appointment ?
          {confirmation.type === "reschedule" && (
            <div className="flex gap-2 text-sm text-primary-dark font-medium pt-4">
              <Checkbox
                checked={isFree}
                onChange={(e) => setIsFree(e.target.checked)}
              >
                Free
              </Checkbox>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditAppoinments;
