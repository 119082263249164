/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import "../../src/assets/js/app";
// import { baricon1, imguser, logo, noteicon, noteicon1, searchnormal, settingicon01, user06 } from './imagepath';
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { getUsername, handleLogout, isValidData } from "helper";
import { signInUsingEmailAddress } from "helper/AuthHelper";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { getUser } from "store/selectors";
import ellipse from "../assets/img/Ellipse.png";
import logoicon from "../assets/img/logoicon.png";
import vector from "../assets/img/Vector.png";
import "./Header.css";
import {
  baricon,
  baricon1,
  noteicon,
  noteicon1,
  settingicon01,
} from "./imagepath";
import { LoginText, Route_Url } from "common/constants";
const Header = () => {
  // States
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailSent, setIsEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [consent, setConsent] = useState(false);

  // Selectors
  const user = useSelector(getUser) || {};
  const { pathname } = useLocation();
  const params = useParams();
  const { name, picture, role, email } = user || {};
  const isValidUser = isValidData(user);

  const userName = name ?? getUsername(email);
  const loggedInAsClient = role?.toLowerCase() === "client";

  const isQuestionnaire = pathname.toLowerCase().includes("question");

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
    document.getElementsByTagName("html")[0].classList.toggle("menu-opened");
    document
      .getElementsByClassName("sidebar-overlay")[0]
      .classList.toggle("opened");
  };
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  useEffect(() => {
    if (pathname.includes(Route_Url.AppointmentBooking) && !isValidUser) {
      document.body.classList.add("mini-sidebar");
    }
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    // maximizeBtn.addEventListener('click', handleClick);

    return () => {
      // maximizeBtn.removeEventListener('click', handleClick);
    };
  }, []);

  const openDrawer = () => {
    const div = document.querySelector(".main-wrapper");
    if (div?.className?.includes("open-msg-box")) {
      div?.classList?.remove("open-msg-box");
    } else {
      div?.classList?.add("open-msg-box");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const titleDesign = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ fontWeight: "bold", fontSize: "24px" }}>Login</span>
    </div>
  );

  const onFormSubmission = async ({ Email }) => {
    setLoading(true);
    try {
      const queryParams = params?.therapistId
        ? `?therapistId=${params.therapistId}`
        : "";
      const response = await signInUsingEmailAddress(Email, queryParams);
      setIsModalVisible(false);
      setIsEmailSent(true);
      setLoading(false);
    } catch (error) {}
  };

  const onCloseSuccessDialog = () => {
    setIsModalVisible(false);
    setIsEmailSent(false);
    setLoading(false);
  };

  return (
    <div className="main-wrapper ">
      <div className="header ">
        <div className="header-left">
          <Link to="/" className="logo">
            <img src={logoicon} height={63} width={62} />
          </Link>
        </div>
        {isValidUser && (
          <Link
            className="ml-10"
            id="toggle_btn"
            to="#"
            onClick={handlesidebar}
          >
            <img src={baricon} alt="" />
          </Link>
        )}

        <Link
          id="mobile_btn"
          className="mobile_btn float-start"
          to="#"
          onClick={handlesidebarmobilemenu}
        >
          <img src={baricon1} alt="" />
        </Link>
        {/* <div className="top-nav-search mob-view">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
            />
            <Link className="btn">
              <img src={searchnormal} alt="" />
            </Link>
          </form>
        </div> */}
        {isValidUser ? (
          <ul className="nav user-menu float-end">
            <li className="nav-item dropdown d-none d-sm-block">
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <img src={noteicon} alt="" />
                <span className="pulse" />{" "}
              </Link>
              <div className="dropdown-menu notifications">
                <div className="topnav-dropdown-header">
                  <span>Notifications</span>
                </div>
                <div className="drop-scroll">
                  <ul className="notification-list">
                    <li className="notification-message">
                      <Link to="/user-activity">
                        <div className="media">
                          <span className="avatar">V</span>
                          <div className="media-body">
                            <p className="noti-details">
                              <span className="noti-title">John Doe</span> added
                              new task{" "}
                              <span className="noti-title">
                                Patient appointment booking
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                4 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="/user-activity">
                        <div className="media">
                          <span className="avatar">V</span>
                          <div className="media-body">
                            <p className="noti-details">
                              <span className="noti-title">
                                Tarah Shropshire
                              </span>{" "}
                              changed the task name{" "}
                              <span className="noti-title">
                                Appointment booking with payment gateway
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                6 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="activities.html">
                        <div className="media">
                          <span className="avatar">L</span>
                          <div className="media-body">
                            <p className="noti-details">
                              <span className="noti-title">Misty Tison</span>{" "}
                              added{" "}
                              <span className="noti-title">
                                Domenic Houston
                              </span>{" "}
                              and{" "}
                              <span className="noti-title">Claire Mapes</span>{" "}
                              to project{" "}
                              <span className="noti-title">
                                Doctor available module
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                8 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="activities.html">
                        <div className="media">
                          <span className="avatar">G</span>
                          <div className="media-body">
                            <p className="noti-details">
                              <span className="noti-title">Rolland Webber</span>{" "}
                              completed task{" "}
                              <span className="noti-title">
                                Patient and Doctor video conferencing
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                12 mins ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="activities.html">
                        <div className="media">
                          <span className="avatar">V</span>
                          <div className="media-body">
                            <p className="noti-details">
                              <span className="noti-title">
                                Bernardo Galaviz
                              </span>{" "}
                              added new task{" "}
                              <span className="noti-title">
                                Private chat module
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                2 days ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link to="/user-activity">View all Notifications</Link>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown d-none d-sm-block">
              <Link
                onClick={openDrawer}
                id="open_msg_box"
                className="hasnotifications nav-link"
              >
                <img src={noteicon1} alt="" />
                <span className="pulse" />{" "}
              </Link>
            </li>
            <li className="nav-item dropdown has-arrow user-profile-list">
              <Link
                to="#"
                className="dropdown-toggle nav-link user-link"
                data-bs-toggle="dropdown"
              >
                <div className="user-names">
                  <h5>{userName}</h5>
                  <span>{role?.toUpperCase()}</span>
                </div>
                {!loggedInAsClient && (
                  <span className="user-img">
                    <img src={picture} alt="profile" />
                  </span>
                )}
              </Link>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/profile">
                  My Profile
                </Link>
                <Link className="dropdown-item" to="/edit-profile">
                  Edit Profile
                </Link>
                <Link className="dropdown-item" to="/settings">
                  Settings
                </Link>
                <div className="dropdown-item" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </li>
            <li className="nav-item ">
              <Link to="/settings" className="hasnotifications nav-link">
                <img src={settingicon01} alt="" />{" "}
              </Link>
            </li>
          </ul>
        ) : (
          <div
            className="position-absolute "
            style={{ right: "26px", top: "13px" }}
          >
            <Button
              className="text-white f-w-800"
              style={{
                backgroundColor: "#2E37A4",
                width: "123px",
                height: "45px",
                fontSize: "16px",
                fontWeight: "500",
                visibility: isQuestionnaire ? "hidden" : "visible",
              }}
              onClick={showModal}
            >
              {LoginText}
            </Button>
          </div>
        )}
        <div className="dropdown mobile-user-menu float-end">
          <Link
            to="#"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical" />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to="/profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="edit-profile.html">
              Edit Profile
            </Link>
            <Link className="dropdown-item" to="/settings">
              Settings
            </Link>
            <Link className="dropdown-item" to="/login">
              Logout
            </Link>
          </div>
        </div>
      </div>

      {/* Notifications */}
      <div className="notification-box">
        <div className="msg-sidebar notifications msg-noti">
          <div className="topnav-dropdown-header">
            <span>Messages</span>
          </div>
          <div className="drop-scroll msg-list-scroll" id="msg_list">
            <ul className="list-box">
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">R</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">Richard Miles </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item new-message">
                    <div className="list-left">
                      <span className="avatar">J</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">John Doe</span>
                      <span className="message-time">1 Aug</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">T</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author"> Tarah Shropshire </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">M</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">Mike Litorus</span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">C</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">
                        {" "}
                        Catherine Manseau{" "}
                      </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">D</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author"> Domenic Houston </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">B</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author"> Buster Wigton </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">R</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author"> Rolland Webber </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">C</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author"> Claire Mapes </span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">M</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">Melita Faucher</span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">J</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">Jeffery Lalor</span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">L</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">Loren Gatlin</span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="list-item">
                    <div className="list-left">
                      <span className="avatar">T</span>
                    </div>
                    <div className="list-body">
                      <span className="message-author">Tarah Shropshire</span>
                      <span className="message-time">12:28 AM</span>
                      <div className="clearfix"></div>
                      <span className="message-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="topnav-dropdown-footer">
            <Link to="/chat">See all messages</Link>
          </div>
        </div>
      </div>

      <Modal
        title={titleDesign}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
        destroyOnClose
        className="ant-modal-content"
      >
        <div style={{ width: "440px", height: "200px" }}>
          <Form name="login-form" onFinish={onFormSubmission}>
            <div
              className=""
              style={{
                width: "366px",
                height: "58px",
                margin: "38px 0 0px 13px",
              }}
            >
              <Form.Item
                className="form-group local-forms "
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Enter your email address",
                  },
                ]}
              >
                <div>
                  <label>
                    Email <span className="login-danger">*</span>
                  </label>
                  <Input className="form-control" type="email" placeholder="" />
                </div>
              </Form.Item>
            </div>

            <Checkbox
              classList
              checked={consent}
              className="text-gray-textColor text-xs mx-4 font-medium flex justify-center items-center mb-4"
              onChange={(e) => setConsent(e.target.checked)}
            >
              By verifying your email, you consent to storing your appointment
              history to provide insights.
            </Checkbox>

            <Form.Item>
              <Button
              disabled={!consent}
                type="primary"
                style={{ width: "366px", height: "45px", margin: "0 0 0 13px" }}
                htmlType="submit"
                loading={loading}
              >
                Verify
              </Button>
            </Form.Item>

            <Form.Item style={{ cursor: "pointer", margin: "0 0 0 164px" }}>
              <span onClick={handleCancel}>Skip Now?</span>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title={null}
        open={emailSent}
        footer={null}
        centered
        closable={false}
        maskClosable={false}
        destroyOnClose
        className="custom-modal"
        style={{
          width: "440px",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <div style={{ margin: "0px 0 21px 0" }} className="position-relative">
          <img src={ellipse} className="w-14 h-14" />
          <img
            src={vector}
            className="position-absolute top-50 start-50 w-5 h-5 inline"
            style={{
              transform: "translate(-50%,-50%)",
            }}
          />
        </div>
        <p className="text-center text-sm font-medium text-gray-textColor">
          An email has been sent to your mailbox. Please check your inbox (and
          spam folder) and click the link to complete the sign-in process.
        </p>
        <Button
          type="primary"
          style={{
            width: "352px",
            height: "45px",
            margin: "18px 0 0 0px",
            background: "linear-gradient(270deg, #2E37A4 44.5%, #4E57CD 100%)",
            borderRadius: "12px",
          }}
          onClick={() => onCloseSuccessDialog()}
        >
          Ok
        </Button>
      </Modal>
    </div>
  );
};

export default Header;
