import { useQuery } from "@tanstack/react-query";
import {
  fetchAppointmentDetailsById,
  fetchAppointmentListForTherapist,
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchAppointmentDetailsById = (appointmentId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_APPOINTMENT_DETAILS, appointmentId],
    queryFn: () => fetchAppointmentDetailsById(appointmentId),
    enabled: !!appointmentId,
  });
