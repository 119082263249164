import { DatePicker, message, Radio, Typography } from "antd";
import { DATE_FORMATS, Route_Url } from "common/constants";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { dayjs, noEmptySpaces } from "helper";

const { Text } = Typography;

export default function UserSignUp(props) {
  const { register, formState } = props?.form;

  const { errors } = formState;
  useEffect(() => {}, [formState]);

  const handleDateChange = (value) => {
    props?.form?.setValue("dob", value);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().subtract(18, "years");
  };

  return (
    <section className="mt-10">
      <form className="flex flex-wrap justify-between">
        <div className="form-group local-forms mb-0 basis-[45%]">
          <label>
            First Name <span className="login-danger">*</span>
          </label>
          <input
            {...register("firstName", {
              required: "Firstname is required",
              minLength: {
                value: 2,
                message: "Firstname should contain atleast 2 characters",
              },
              maxLength: {
                value: 50,
                message: "Firstname should not exceed 50 characters",
              },
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Should contain only alphabets",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            style={{
              borderColor: errors?.firstName?.type ? "red" : "",
            }}
          />
          {errors?.firstName && (
            <Text type="danger " className="pl-2">
              {errors?.firstName?.message}
            </Text>
          )}
        </div>

        <div className="form-group local-forms mb-4 basis-[45%]">
          <label>
            Last Name <span className="login-danger">*</span>
          </label>
          <input
            {...register("lastName", {
              required: "Last name is required",
              minLength: {
                value: 2,
                message: "Last name should contain atleast 2 characters",
              },
              maxLength: {
                value: 50,
                message: "Last name should not exceed 50 characters",
              },
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Should contain only alphabets",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            style={{
              borderColor: errors?.lastName?.type ? "red" : "",
            }}
            aria-invalid={errors?.lastName ? "true" : "false"}
          />
          {errors?.lastName?.type && (
            <Text type="danger" className="pl-2">
              {errors?.lastName?.message}
            </Text>
          )}
        </div>
        {/* Gender */}

        <div className="form-group select-gender mt-0 text-sm">
          <label className="gen-label">
            Gender<span className="login-danger">*</span>
          </label>
          <div className="form-check-inline">
            <label>
              <input
                {...register("gender", { required: true })}
                type="radio"
                value={"male"}
                name="gender"
                id="gender"
                className="form-check-input"
              />
              Male
            </label>
          </div>
          <div className="form-check-inline">
            <label>
              <input
                {...register("gender", { required: true })}
                type="radio"
                name="gender"
                id="gender"
                value={"female"}
                className="form-check-input"
              />
              Female
            </label>
          </div>
          <div className="form-check-inline">
            <label>
              <input
                {...register("gender", { required: true })}
                type="radio"
                name="gender"
                id="gender"
                value={"others"}
                className="form-check-input"
              />
              Others
            </label>
          </div>
          {errors?.gender?.type && (
            <Text type="danger " className="pl-2 block">
              Gender is required
            </Text>
          )}
        </div>

        {/* Email Address */}
        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            E-mail <span className="login-danger">*</span>
          </label>
          <input
            {...register("email", {
              required: "Email is required",
              validate: noEmptySpaces,
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "Email address is not valid",
              },
            })}
            className="form-control"
            type="email"
            placeholder=""
            style={{
              borderColor: errors?.email?.type === "required" ? "red" : "",
            }}
            aria-invalid={errors?.email ? "true" : "false"}
            required
          />
          {errors?.email && (
            <Text type="danger " className="pl-2">
              {errors.email?.message}
            </Text>
          )}
        </div>

        {/* Phone Number */}
        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            Phone Number <span className="login-danger">*</span>
          </label>
          <input
            {...register("mobile", {
              required: "Mobile number is required",
              validate: noEmptySpaces,
              maxLength: {
                value: 15,
                message: "Mobile number should have 15 digits",
              },
              minLength: {
                value: 10,
                message: "Mobile number should have 10 digits",
              },
              pattern: {
                value: /^[\d\+\-\(\)\s]+$/,
                message: "Invalid number",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            min={0}
            required
            style={{
              borderColor: errors?.mobile?.type === "required" ? "red" : "",
            }}
            aria-invalid={errors?.mobile ? "true" : "false"}
          />

          {errors?.mobile?.type && (
            <Text type="danger " className="pl-2">
              {errors?.mobile?.message}
            </Text>
          )}
        </div>

        {/* Date of Birth */}

        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            Date of Birth <span className="login-danger">*</span>
          </label>
          <DatePicker
            {...register("dob", { required: true })}
            className="form-control datetimepicker"
            onChange={handleDateChange}
            format={DATE_FORMATS["MM/DD/YYYY"]}
            disabledDate={disabledDate}
            inputReadOnly
            showToday={false}
            style={{
              borderColor: errors?.dob?.type === "required" ? "red" : "",
            }}
            aria-invalid={errors?.dob ? "true" : "false"}
          />

          {errors?.dob?.type === "required" && (
            <Text type="danger " className="pl-2">
              Date of birth is required
            </Text>
          )}
        </div>

        {/* Last 4 digits of SSN */}

        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            Last 4 digits of SSN <span className="login-danger">*</span>
          </label>
          <input
            {...register("ssn", {
              required: "SSN is required",
              maxLength: {
                value: 4,
                message: "SSN should be 4 digits",
              },
              minLength: {
                value: 4,
                message: "SSN should be 4 digits",
              },
              pattern: {
                value: /^\d+$/,
                message: "Invalid SSN",
              },
            })}
            className="form-control"
            type="number"
            placeholder=""
            min={0}
            required
            style={{
              borderColor: errors?.ssn?.type ? "red" : "",
            }}
            aria-invalid={errors?.ssn ? "true" : "false"}
          />

          {errors?.ssn?.type && (
            <Text type="danger " className="pl-2">
              {errors?.ssn?.message}
            </Text>
          )}
        </div>

        {/* Education */}

        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            Education <span className="login-danger">*</span>
          </label>
          <input
            {...register("education", {
              required: "Education is required",
              validate: noEmptySpaces,
              minLength: {
                value: 5,
                message: "Education should contain atleast 5 characters",
              },
              maxLength: {
                value: 50,
                message: "Education should not exceed 50 characters",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            required
            style={{
              borderColor: errors?.education?.type ? "red" : "",
            }}
            aria-invalid={errors?.education ? "true" : "false"}
          />
          {errors?.education && (
            <Text type="danger " className="pl-2">
              {errors.education?.message}
            </Text>
          )}
        </div>

        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            Years of Experience
            <span className="login-danger">*</span>
          </label>
          <input
            {...register("exp", {
              required: "Experience is required",
              maxLength: {
                value: 2,
                message: "Experience should be of 2 digits",
              },
              pattern: {
                value: /^\d+$/,
                message: "Not an valid experience",
              },
            })}
            className="form-control"
            type="text"
            placeholder=""
            min={0}
            required
            style={{
              borderColor: errors?.exp?.type === "required" ? "red" : "",
            }}
            aria-invalid={errors?.exp ? "true" : "false"}
          />

          {errors?.exp?.type && (
            <Text type="danger " className="pl-2">
              {errors?.exp?.message}
            </Text>
          )}
        </div>

        <div className="basis-[45%]" />

        {/* About yourself */}

        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            Address <span className="login-danger">*</span>
          </label>
          <textarea
            {...register("address", {
              required: "Address is required",
              validate: noEmptySpaces,
              maxLength: {
                value: 300,
                message:"Only 300 characters are allowed"
              }
            })}
            className="form-control"
            rows={3}
            cols={30}
            defaultValue={""}
            style={{
              borderColor: errors?.address ? "red" : "",
            }}
            aria-invalid={errors?.address ? "true" : "false"}
            required
          />
          {errors?.address?.type && (
            <Text type="danger " className="pl-2">
              {errors?.address?.message}
            </Text>
          )}
        </div>

        <div className="form-group local-forms mb-6 basis-[45%]">
          <label>
            About yourself <span className="login-danger">*</span>
          </label>
          <textarea
            {...register("about", {
              required: "About is required",
              validate: noEmptySpaces,
              maxLength: {
                value: 300,
                message:"Only 300 characters are allowed"
              }
            })}
            className="form-control"
            rows={3}
            cols={30}
            defaultValue={""}
            style={{
              borderColor: errors?.about ? "red" : "",
            }}
            aria-invalid={errors?.about ? "true" : "false"}
            required
          />
          {errors?.about?.type && (
            <Text type="danger " className="pl-2">
              {errors?.about?.message}
            </Text>
          )}
        </div>

        {/* Profile Picture */}
        {/* <Form.Item
          name="picture"
          className="
          rules={[
            {
              required: true,
              message: "Picture is required",
            },
          ]}
        >
          <div className="form-group local-top-form mb-6 basis-[45%]">
            <label className="local-top">
              Avatar <span className="login-danger">*</span>
            </label>
            <div className="settings-btn upload-files-avator">
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                className="hide-input"
              />
              <label>
                Choose File
              </label>
            </div>
          </div>
        </Form.Item> */}
      </form>

      <div className="text-base text-[rgba(51, 52, 72, 0.50)] ">
        Already have an account ?{" "}
        <Link
          to={Route_Url.Login}
          className="text-primary-dark font-medium pl-2"
        >
          Sign In
        </Link>
      </div>
    </section>
  );
}
