import { useQuery } from "@tanstack/react-query";
import {
    fetchInitialAssessmentQuestions
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

const initialData = {
    "clientName": "Ajeeth Natarajan",
    "clientId": "67051dab25aa4db2a89c6ad7",
    "appointmentId": "670882b865c9751f4c8bfb5e",
    "appointmentQuestions": {
        "questionnairId": "670942282b46762f55d80852",
        "questionnairName": "Appointment Questionnaire",
        "questionnairDescription": "By completing the following form you will complete the first step of the SAP Process. You will provide information that is required for the SAP Reports, and also complete assessments that will help your SAP in their evaluation. This form is to be completed at least 24 hours before your appointment.",
        "language": "en",
        "sections": [
            {
                "id": "6709437d2b46762f55d80855",
                "sectionNumber": 1,
                "name": "Client Information",
                "questions": [
                    {
                        "sequence": 1,
                        "text": "First Name",
                        "additionalText": null,
                        "isMandatory": true,
                        "type": "text",
                        "options": [],
                        "showTextBoxForOther": false,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    },
                    {
                        "sequence": 2,
                        "text": "Last Name",
                        "additionalText": null,
                        "isMandatory": true,
                        "type": "text",
                        "options": [],
                        "showTextBoxForOther": false,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    },
                    {
                        "sequence": 3,
                        "text": "Date of Birth",
                        "additionalText": null,
                        "isMandatory": true,
                        "type": "date",
                        "options": [],
                        "showTextBoxForOther": false,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    }
                ],
                "sectionCompleted": false
            },
            {
                "id": "670943a82b46762f55d80856",
                "sectionNumber": 2,
                "name": "Regulation Violation Information",
                "questions": [
                    {
                        "sequence": 1,
                        "text": "Violation Test Type",
                        "additionalText": "Get the exact info from Clearinghouse or Lab Report",
                        "isMandatory": true,
                        "type": "radio",
                        "options": [
                            "Urine",
                            "Breathalyzer",
                            "Hair Follicle (non DOT)",
                            "Saliva",
                            "Other"
                        ],
                        "showTextBoxForOther": true,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    },
                    {
                        "sequence": 2,
                        "text": "Reason for Violation",
                        "additionalText": "Get the exact info from Clearinghouse or Lab Report",
                        "isMandatory": true,
                        "type": "radio",
                        "options": [
                            "Drug Test Refusal",
                            "Marijuana Metabolite (Delta9-THCA)",
                            "Methamphetamine",
                            "Amphetamine",
                            "Cocaine Metabolite (BZE)",
                            "HYC",
                            "OXYC, OXYM",
                            "Alcohol Concentration of 0.04 or greater",
                            "Other"
                        ],
                        "showTextBoxForOther": true,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    },
                    {
                        "sequence": 3,
                        "text": "Date of Results",
                        "additionalText": null,
                        "isMandatory": true,
                        "type": "date",
                        "options": [],
                        "showTextBoxForOther": false,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    },
                    {
                        "sequence": 4,
                        "text": "Date contacted for SAP Evaluation",
                        "additionalText": null,
                        "isMandatory": true,
                        "type": "date",
                        "options": [],
                        "showTextBoxForOther": false,
                        "hasDynamicHandling": false,
                        "userResponse": null
                    },
                    {
                        "sequence": 5,
                        "text": "Is this your first drug or alcohol regulation violation",
                        "additionalText": "Get the exact info from Clearinghouse or Lab Report",
                        "isMandatory": true,
                        "type": "radio",
                        "options": [
                            "Yes",
                            "No"
                        ],
                        "showTextBoxForOther": true,
                        "secionNavigation": true,
                        "secionNavigationProps": { "Yes" : 5, "No" : 3} ,
                        "userResponse": null
                    }
                ],
                "sectionCompleted": false
            }
        ]
    }
}
export const useFetchAssessmentQuestions = (apppointmentId, lang) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_ASSESSMENT_QUESTIONS,apppointmentId,lang],
    queryFn: () => fetchInitialAssessmentQuestions(apppointmentId, lang),
    enabled: !!apppointmentId,
    retry: false,
    retryOnMount: false,
  });
