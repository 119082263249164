/* eslint-disable no-unused-vars */
import { Avatar, Table } from "antd";
import {
  DATE_FORMATS,
  formatDateString,
  Route_Url,
  Test_Therapist_Id,
  TIME_FORMATS,
} from "common/constants";
import { useFetchAppointmentList } from "common/hooks/queries";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getInitials } from "helper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUser } from "store/selectors";
import Header from "../../Header";
import { itemRender } from "../../Pagination";
import Sidebar from "../../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  pdficon4
} from "../../imagepath";
import "./styles.css";

const AppoinmentList = () => {
  const user = useSelector(getUser);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");

  const therapistId = user?.id;

  const [pagination, setPagination] = useState({
    limit: 100,
    current: 1,
    total: 0,
    pages: 1,
  });
  const { data, isLoading, refetch } = useFetchAppointmentList(
    therapistId,
    pagination.limit,
    pagination.current
  );

  useEffect(() => {
    if (data) {
      setPagination({
        limit: data?.limit,
        current: data?.page,
        pages: data?.totalPages,
        total: data?.totalResults,
      });
    }
  }, [data]);

  const appointments = data?.results;

  const StatusTags = {
    undefined: (
      <div className="w-[5.3125rem] text-sm font-medium text-light-40 flex items-center justify-center h-7 rounded-md bg-light-20">
        Pending
      </div>
    ),
    false: (
      <div className="w-[5.3125rem] text-sm font-medium text-light-40 flex items-center justify-center h-7 rounded-md bg-light-20">
        Pending
      </div>
    ),
    true: (
      <div className="w-[5.3125rem] text-sm font-medium text-success-50 flex items-center justify-center h-7 rounded-md bg-success-10">
        Completed
      </div>
    ),
  };

  const handleSearchChange = (event) => {
    const value = event?.target?.value;
    setSearchText(value);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Name",
      width: 200,
      render: (text, record) => (
        <Link
        to={`${Route_Url.ViewAppointment}/${record?.id}`}
        >
          <h2 className="profile-image flex justify-start items-center">
            <Avatar className="bg-primary-dark bg-opacity-70 w-7 h-7 flex items-center">
              {getInitials(record?.client?.firstName, record?.client?.lastName)}
            </Avatar>

            <span className="text-sm pl-2.5 font-medium text-gray-80 ">
              {`${record?.client?.lastName} ${record?.client?.firstName}`}</span>
          </h2>
        </Link>
      ),
    },
    {
      title: "Mobile",
      ellipsis: true,
      width: 120,
      render: (_, record) => <span>{record?.client?.mobile}</span>,
      sorter: (a, b) => a.client?.mobile.length - b.client?.mobile.length,
    },
    {
      title: "Email",
      ellipsis: true,
      width: 150,
      render: (value) => <span>{value?.client?.email}</span>,
    },
    ,
    {
      title: "Date",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (value) => (
        <span>
          {formatDateString(
            value?.appointmentStartTimestamp,
            DATE_FORMATS["MM/DD/YYYY"]
          )}
        </span>
      ),
      sorter: (a, b) => new Date(a.appointmentDate) - new Date(b.appointmentDate),
    },
    {
      title: "Time",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (value) => (
        <span>
          {formatDateString(
            value?.appointmentStartTimestamp,
            TIME_FORMATS["hh:mm a"]
          )}
        </span>
      ),
      sorter: (a, b) => new Date(b.appointmentStartTimestamp) - new Date(a.appointmentStartTimestamp),
    },
    {
      title: "Score",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (value) => null,
    },

    {
      title: "Assessment",
      dataIndex: "initialAssessmentSubmitted",
      ellipsis: true,
      width: 120,
      render: (_, record) => StatusTags[record.initialAssessmentSubmitted],
    },
    {
      title: "",
      dataIndex: "",
      width: 50,
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`${Route_Url.ViewAppointment}/${record?.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const onPaginationChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      limit: pageSize,
    }));
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item5"
        id1="menu-items5"
        activeClassName={Route_Url.AppointmentList}
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appoinment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Appoinment List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Appoinment List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  onChange={handleSearchChange}
                                  value={searchText}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <Link to="#" className=" me-2">
                            <img src={pdficon} alt="#" />
                          </Link>
                          <Link to="#" className=" me-2"></Link>
                          <Link to="#" className=" me-2">
                            <img src={pdficon3} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={pdficon4} alt="#" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: pagination?.total,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          //showSizeChanger: true,
                          onChange: onPaginationChange,

                          itemRender: itemRender,
                          pageSize: pagination?.limit,
                          current: pagination.current,
                        }}
                        columns={columns}
                        id="appointmentlist"
                        dataSource={appointments}
                        scroll={{ x: "80vw" }}
                        rowSelection={rowSelection}
                        loading={isLoading}
                        className="text-sm"
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button type="submit" className="btn btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <></>
    </>
  );
};

export default AppoinmentList;
