import React from "react";
import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Create a client
const queryClient = new QueryClient({defaultOptions: {
  queries: {
    retry: false,
    cacheTime: 0
  },
}});
export default function AppQueryClientProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen /> */}
      {children}
    </QueryClientProvider>
  );
}

AppQueryClientProvider.propTypes = {
  children: PropTypes.node,
};
