import { verifyUser } from "services/ApiService";
import { notificationActions, progressActions, userActions } from "../slicers";
import { RouteHistory } from "helper";
import { Route_Url } from "common/constants";

export const incrementLoader = () => {
  return (dispatch) => {
    dispatch(progressActions.increment());
  };
};

export const decrementLoader = () => {
  return (dispatch) => {
    dispatch(progressActions.decrement());
  };
};

export const loadUser = () => {
  return async (dispatch) => {
    incrementLoader();
    try {
      const response = await verifyUser();
      dispatch(userActions.setUser(response));
      displayNotification({
        type: "success",
        message: `Welcome  ${response?.name} !!`,
        description: "You have logged in successfully",
      });
      decrementLoader();
    } catch (error) {
      console.log(error);
    }
  };
};

export const setUser = (username) => {
  return (dispatch) => {
    dispatch(userActions.setUser(username));
  };
};

export const displayNotification = (payload) => {
  return (dispatch) => {
    dispatch(notificationActions.addNotification(payload));
  };
};

export const clearNotification = () => {
  return (dispatch) => {
    dispatch(notificationActions.clearNotification());
  };
};
