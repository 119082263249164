/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Route_Url, Test_Therapist_Id } from "common/constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getUser } from "store/selectors";
import { dashboard, menuicon08 } from "./imagepath";
import {
  isClient,
  isClientRoute,
  isTherapist,
  isTherapistRoute,
  isValidData,
  Storage_Keys,
  StorageHelper,
} from "helper";

const Sidebar = (props) => {
  const [sidebar, setSidebar] = useState("");
  const { pathname } = useLocation();

  // Selectors
  const user = useSelector(getUser) || {};
  const isValidUser = !isEmpty(user);

  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    // const ulDiv = document.querySelector(`.${item1}`);
    // e?.target?.className
    //   ? (ulDiv.style.display = "none")
    //   : (ulDiv.style.display = "block");
    // e?.target?.className
    //   ? div.classList.remove("subdrop")
    //   : div.classList.add("subdrop");
  };

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, []);

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  const loggedInAsClient = isClient(user);
  const loggedInAsTherapist = isTherapist(user);

  const isActiveRoute = (route) =>
    pathname === route ? "text-primary-dark font-bold" : "";

  const therapistRoutes = (
    <>
      <li className="submenu">
        <Link
          to={Route_Url.AppointmentList}
          id="menu-item5"
          onClick={(e) => handleClick(e, "menu-item5", "menu-items5")}
        >
          <span className="menu-side">
            <img src={menuicon08} alt="" />
          </span>
          <span> Appointment List </span>
        </Link>
      </li>
    </>
  );

  const therapistId = StorageHelper.load(Storage_Keys.TherapistIDForClient);

  const clientRoutes = (
      <>
        {!!therapistId && (
          <li className="submenu">
            <Link
              to={`${Route_Url.AppointmentBooking}/${therapistId}`}
              id="menu-item5"
              onClick={(e) => handleClick(e, "menu-item5", "menu-items5")}
            >
              <span className="menu-side">
                <img src={menuicon08} alt="" />
              </span>
              <span> Appointment Booking </span>
            </Link>
          </li>
        )}
      </>
    ) ;
  return (
    <>
      <div className="sidebar" id="sidebar">
        {/* <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        > */}
        <div className="sidebar-inner slimscroll">
          <div
            id="sidebar-menu"
            className="sidebar-menu"
            onMouseLeave={expandMenu}
            onMouseOver={expandMenuOpen}
          >
            <ul>
              {isValidUser && (
                <li className="submenu">
                  <Link
                    to={
                      loggedInAsClient
                        ? Route_Url.PatientDashboard
                        : Route_Url.DoctorDashboard
                    }
                    id="menu-item"
                    onClick={(e) => {
                      handleClick(e, "menu-item", "menu-items");
                    }}
                  >
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>
                    <span> Dashboard </span>
                  </Link>
                </li>
              )}
              {loggedInAsTherapist && therapistRoutes}
              {loggedInAsClient && clientRoutes}
            </ul>
          </div>
        </div>
        {/* </Scrollbars> */}
      </div>
    </>
  );
};
export default Sidebar;
