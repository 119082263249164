const Test_Therapist_Id = "670aa2354c33a83993693852";
const LoginText = "Sign in";

const Default_Duration_In_Minutes = 60;

const DEFAULT_DISABLED_HOURS = [
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  0,
  ,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
];

const Languages = {
  EN: "English",
  ES: "Spanish",
};

const Plan_Types = {
  "starter": "Starter",
  "professional": "Professional",
  "enterprise": "Enterprise",
}

export {
  Test_Therapist_Id,
  LoginText,
  Default_Duration_In_Minutes,
  DEFAULT_DISABLED_HOURS,
  Languages,
  Plan_Types
};
