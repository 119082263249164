import { useQuery } from "@tanstack/react-query";
import { getUserById } from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchUserById = (userId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_USER_BY_ID,userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId,
  });
