import { store } from "store";
import { notificationActions } from "store/slicers";

export const getStoreDispatch = store.dispatch;

export const dispatchNotification = (type, message , description = "") =>  
  store.dispatch(notificationActions.addNotification({
  type,
  message,
  description 
}))
