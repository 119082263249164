import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Select,
  Skeleton,
  Spin,
  TimePicker,
} from "antd";
import {
  DATE_FORMATS,
  Default_Duration_In_Minutes,
  TIME_FORMATS,
} from "common/constants";
import { useFetchAppointmentDetailsById } from "common/hooks/queries";
import {
  checkIsValidDateObj,
  convertDateToISO,
  dayjs,
  dispatchNotification,
  getQuestionErrors,
} from "helper";
import { useForm } from "react-hook-form";
import AppointmentForm from "../../../UserForm";
import "./styles.css";
import AssessmentQuestionsDisplay from "components/assessment/AssessmentQuestionsDisplay";
import { useCallback, useEffect, useMemo, useState } from "react";
import { submitAssessmentAnswers } from "services/ApiService";
import { useActions } from "common/hooks";
import { first } from "lodash";

export const InitialAssessmentTab = (props) => {
  const { appointmentId } = props;
  const [assessmentForm] = Form.useForm();
  const {
    data,
    isLoading,
    refetch: refetchInitialAssessment,
  } = useFetchAppointmentDetailsById(appointmentId);

  const [loading, setLoading] = useState(false);
  const { displayNotification } = useActions();
  const { firstName, lastName, gender, dob, email, mobile, ssn, address } =
    data?.client || {};
  const appointmentDetails = useMemo(
    () => ({
      firstName,
      lastName,
      gender,
      email,
      mobile,
      ssn,
      address,
      dob: dayjs(dob),
      notes: data?.notes,
    }),
    [firstName, lastName, gender, dob, email, mobile, ssn, address, data]
  );

  const { startDateTime, therapist } = {
    startDateTime: data?.appointmentStartTimestamp,
    endDateTime: data?.appointmentEndTimestamp,
    therapist: data?.therapist,
  };
  const appointmentForm = useForm({ values: appointmentDetails });
  const appointmentDate = dayjs(startDateTime);
  const appointmentTime = dayjs(startDateTime);

  const { sections, language, title, description } =
    data?.initialAssessmentResponse?.[0] || [];

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs()?.startOf("day");
  };

  const formatValues = useCallback((data) => {
    const formatted = [];
    Object.entries(data).map(([key, value]) => {
      // this is for Date Object
      if (typeof value === "object" && checkIsValidDateObj(value)) {
        formatted.push({
          questionNumber: key,
          answers: [convertDateToISO(value)],
        });
      }
      // Check the radio type Other & parse the value
      if (!key.includes("Other") && typeof value == "string") {
        formatted.push({
          questionNumber: key,
          otherAnswer: data[`${key}-Other`] ? [data[`${key}-Other`]] : [],
          answers: [data[key]],
        });
      }
    });
    return formatted;
  }, []);

  const [currentSection, setCurrentSection] = useState(null);

  useEffect(() => {
    if (!currentSection && sections?.length > 0) {
      setCurrentSection(first(sections)?.sectionNumber)
    }
  }, [sections, currentSection]);

  const sectionDetails = sections?.find(
    (sec) => sec.sectionNumber === currentSection
  );

  const isFinalSection = useMemo(
    () =>
      sections?.findIndex(
        (sec) => sec?.sectionNumber === sectionDetails?.sectionNumber
      ) ===
      sections?.length - 1,
    [sections, sectionDetails]
  );

  const handleSectionNavigation = (isFinalSection) => {
    const currentIndex = sections.findIndex(
      (sec) => sec.sectionNumber === sectionDetails?.sectionNumber
    );
    if (isFinalSection) return;
    const nextSection = sections[currentIndex + 1];
    setCurrentSection(nextSection?.sectionNumber);
  };

  const saveAnswers = async ({ data }) => {
    setLoading(true);
    const payload = {
      response: formatValues(data),
      sectionNumber: sectionDetails?.sectionNumber,
      finalSubmission: isFinalSection,
      language,
      appointmentId,
    };

    try {
      const response = await submitAssessmentAnswers(payload);
      dispatchNotification(
        "success",
        `Section ${payload?.sectionNumber} has been saved`
      );
      refetchInitialAssessment();
      setLoading(false);
      handleSectionNavigation(isFinalSection);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onSaveAssessment = () => {
    assessmentForm
      ?.validateFields()
      .then((data) => {
        saveAnswers({ data });
      })
      .catch((error) => {
        const errors = getQuestionErrors(error);
        displayNotification({
          type: "error",
          message: `${errors?.join(" , ")} ${errors?.length > 1 ? "are" : "is"} required`,
          description: "Please fill the mandatory fields",
        });
      });
  };

  const sectionOptions = useMemo(
    () =>
      sections?.map((s, index) => ({
        label: `Section ${s.sectionNumber}`,
        value: s?.sectionNumber,
        id: "section-select",
      })),
    [sections, currentSection]
  );

  const handleSectionChange = (id) => {
    setCurrentSection(id);
  };

  return (
    <Skeleton loading={isLoading} active>
      <section className="flex flex-col xl:flex-row flex-wrap gap-4 min-h-[25.75]">
        <section
          id="appdetails"
          className="basis-[38%] bg-primary-10 p-6 flex-col text-sm font-medium rounded-xl"
        >
          {/* <div className="flex flex-row justify-between">
            <div>Appointment Id : </div>
            <div className="w-[5.5rem] bg-white text-base text-ellipsis text-primary-dark flex justify-center items-center mb-3.5">
              #1024
            </div>
          </div> */}
          <div className="flex flex-row justify-between mb-5">
            <div>Therapist Name : </div>
            <div>Dr. {therapist?.name} </div>
          </div>
          <div>
            <span className="text-dark-40 font-medium text-sm">
              Appointment Date
            </span>
            <DatePicker
              className="w-100 h-12 mt-3 mb-4 text-sm text-gray-textColor rounded-xl"
              value={appointmentDate}
              inputReadOnly
              open={false}
              allowClear={false}
              format={DATE_FORMATS["MM/DD/YYYY"]}
              disabledDate={disabledDate}
            />
          </div>
          <div>
            <span className="text-dark-40 font-medium text-sm">
              Appointment Time
            </span>
            <TimePicker
              value={appointmentTime}
              inputReadOnly
              open={false}
              className="w-100 h-12 mt-3 mb-4 text-sm text-gray-textColor rounded-xl"
              format={TIME_FORMATS["hh:mm a"]}
            />
          </div>
          <div>
            <span className="text-dark-40 font-medium text-sm block">
              Duration
            </span>
            <InputNumber
              value={Default_Duration_In_Minutes}
              suffix="minutes"
              inputMode="numeric"
              readOnly
              className="w-100 flex items-center h-12 mt-3   rounded-xl"
            />
          </div>
        </section>
        <section className="basis-[60%] flex-row shadow-lg border border-primary-20 px-6 py-5 font-medium rounded-xl">
          {appointmentDetails && (
            <AppointmentForm
              form={appointmentForm}
              isReadonly={true}
              values={appointmentDetails}
            />
          )}
        </section>
      </section>

      <section className="flex flex-row flex-wrap gap-4 justify-between items-center pt-[3.375rem]">
        <Select
          id="section"
          className="w-[10.25rem] text-white h-10 section-select"
          value={currentSection}
          onChange={handleSectionChange}
          options={sectionOptions}
        />
        <header className="text-2xl font-semibold text-right basis-1/2 whitespace-nowrap">
          {sectionDetails?.name}
        </header>
      </section>

      <section>
        <Spin spinning={loading} tip="Saving Response">
          <AssessmentQuestionsDisplay
            form={assessmentForm}
            sectionDetails={sectionDetails}
            loading={loading}
          />
        </Spin>
      </section>

      <section className="flex justify-end gap-4 text-base font-medium pt-12">
        <Button
          loading={loading}
          onClick={() => onSaveAssessment()}
          style={{
            background: "linear-gradient(270deg, #2E37A4 44.5%, #4E57CD 100%)",
          }}
          className="rounded-lg min-w-[11rem] h-10 text-white border-none"
        >
          {isFinalSection ? "Submit" : "Save"}
        </Button>
      </section>
    </Skeleton>
  );
};
