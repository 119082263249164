import { useQuery } from "@tanstack/react-query";
import {
    getInitialEvaluationQuestions
} from "services/ApiService";
import { AppQueriKeys } from "./AppQueries";

export const useFetchInitialEvaluationQuestions = (appointmentId) =>
  useQuery({
    queryKey: [AppQueriKeys.GET_INITIAL_EVALUATION_QUESTIONS, appointmentId],
    queryFn: () => getInitialEvaluationQuestions(appointmentId),
    enabled: !!appointmentId,
  });
