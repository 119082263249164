import React from "react";
import { ConfigProvider } from "antd";
import PropTypes from 'prop-types';
export default function AntDesignProvider(props) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2E37A4",
          fontFamily: "Poppins",
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
}

AntDesignProvider.propTypes = {
  children: PropTypes.node
}

