export const Route_Url = {
  // Client
  Login: "/login",
  SignUp: "/signup",
  EmailVerificaiton: "/linksent",
  Client: "/client",
  AppointmentBooking: "/client/appointmentbooking",
    PatientDashboard: "/client/dashboard",
  Questionnaire: "/client/questions/:appointmentId",

  // Doctor
  Therapist: "/therapist",
  DoctorDashboard: "/therapist/dashboard",
  AppointmentList: "/therapist/appointmentlist",
  EditAppointment: "/therapist/editappointment/:appointmentId",
  ViewAppointment: "/therapist/editappointment",

  //Login Callback with Google
  GoogleLoginCallback : "/google-login-callback",
};
