import { Button, Divider, Steps } from "antd";

import { useMemo, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { useActions } from "common/hooks";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import SubscriptionPlan from "./SubscriptionPlans";
import UserSignUp from "./UserSignUp";

import { DATE_FORMATS, Route_Url } from "common/constants";
import { createUserAccount, getUserbyEmail } from "services/ApiService";
import { useSelector } from "react-redux";
import { getUser } from "store/selectors";
import { getCurrentTimeZone, isTherapist, isValidData } from "helper";
import RoleRedirect from "components/RoleRedirect";

function SignUpPage() {
  const signupForm = useForm();
  const navigate = useNavigate();
  const { displayNotification } = useActions();
  const user = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [plan, setPlan] = useState("");

  const isLastIndex = current === 3;

  const handlePlanSelection = (value) => {
    setPlan(value);
  };

  const handleError = (errors) => {
    console.log(errors);
  };
  
  const handleFormSubmission = async (values) => {
    setUserDetails(values);

    try {
      setLoading(true);
      const user = await getUserbyEmail(values?.email);
      if (user && isTherapist(user)) {
        displayNotification({
          type: "error",
          message: `${values?.email} already exists`,
          decription: "Please log in using existing account",
        });
      } 
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCurrent((prev) => prev + 1);
      console.log(error);
    }
  };

  const handlePrevNavigation = () => {
    setCurrent((prev) => prev - 1);
  };

  const handleNextNavigation = async () => {
    if (current === 0) {
      signupForm.handleSubmit(handleFormSubmission, handleError)();
    }

    if (current === 1) {
      if (plan) {
        setCurrent((prev) => prev + 1);
      } else {
        displayNotification({
          type: "error",
          message: "Please select a plan",
        });
      }
    }

    if (current === 2) {
      setCurrent((prev) => prev + 1);
    }

    if (current === 3) {
      const payload = {
        ...userDetails,
        dob: userDetails?.dob.format(DATE_FORMATS?.["MM/DD/YYYY"]),
        name: userDetails?.firstName,
        role: "therapist",
        timezone: getCurrentTimeZone(),
        subscription: {
          selected: true,
          planId: plan,
          paid: true,
        },
      };

      // Create user account
      try {
        setLoading(true);
        const response = await createUserAccount(payload);
        displayNotification({
          type: "success",
          message: "User account created successfully",
          description: `Please log in using ${response?.email}`
        });
        setLoading(false);
        navigate(Route_Url.Login);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const stepsComponents = {
    0: <UserSignUp form={signupForm} />,
    1: (
      <SubscriptionPlan
        selectedPlan={plan}
        planSelected={handlePlanSelection}
      />
    ),
    2: null,
    3: null,
  };

  const getStepStatus = (stepIndex) => {
    if (current === stepIndex) return "process";
    if (current > stepIndex) return "finish";
    return "wait";
  };

  if (isValidData(user)) {
    return (<Navigate to="/" />);
  }

  return (
    <section className="h-screen w-full p-4">
      <div className="bg-white px-14 pt-8 rounded-3xl shadow-lg">
        {/* <div className="flex justify-center items-center py-6">
          <img
            src={logoicon}
            alt=""
            className="w-[6.25rem] h-[6.25rem] object-cover inline-block mb-5"
          />
        </div> */}

        <Steps
          responsive
          current={current}
          items={[
            {
              title: "Signup",
              status: getStepStatus(0),
              icon: <i className="fa fa-address-card mt-1" />,
            },
            {
              title: "Choose Plan",
              status: getStepStatus(1),
              icon: <i className="fa fa-cart-arrow-down mt-1" />,
            },
            {
              title: "Payment",
              status: getStepStatus(2),
              icon: <i className="fa fa-credit-card mt-1" />,
            },
            {
              title: "Done",
              status: getStepStatus(3),
              icon: <i className="fa fa-check-circle mt-1" />,
            },
          ]}
        />
        <section className="max-h-[60dvh] overflow-y-auto">
          <section>{stepsComponents[current]}</section>
        </section>
        <Divider type="horizontal" />
        <div className="flex gap-4 justify-end items-center pb-6">
          {current > 0 && (
            <Button
              onClick={handlePrevNavigation}
              loading={loading}
              htmlType="submit"
              className="w-[10rem] float-right h-12 flex justify-center items-center bg-gray-20 text-gray-textColor font-medium text-sm"
            >
              Previous
            </Button>
          )}
          <Button
            onClick={() => handleNextNavigation()}
            loading={loading}
            htmlType="submit"
            style={{
              background:
                "linear-gradient(270deg, #2E37A4 44.5%, #4E57CD 100%)",
            }}
            className="w-[10rem] float-right h-12 flex justify-center items-center text-white font-medium text-sm"
          >
            {isLastIndex ? "Create Account" : "Next"}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default SignUpPage;
