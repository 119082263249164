export const API_ROUTES = {
    "PATIENT": {
        "verifyUser": "/auth/verify-email",
        "bookAppointment": "/appointments/createAppointment",
        "getAssessmentQuestions": "/questions/initalAssessment",
        "submitQuestions": "/questions/saveInitialAssessmentResponse",
        "getAssessmentSavedResponse": "/questions/getInitialAssessmentBySection",
    },
    "DOCTOR": {
        "login": "",
        "createuser": "/users",
        "getSubscriptions": "/subscriptions",
        "getUser": "/users",
        "submitInitialEvaluationAnswers": "questions/saveInitialEvaluationResponse",
        "submitTreatmentPlan": "questions/saveTreatmentPlanResponse",
        "getCalendarBookings": "/appointments/getAppointmentsByTherapist",
        "getAppointmentList": "/appointments/getAppointmentsList",
        "getAppointmentDetails": "/appointments/getAppointmentById",
        "updateAppointmentStatus": "appointments/udpateAppointment",
        "getInitialEvaluationPlan": "/questions/initalEvaluation",
        "getTreatmentPlan": "questions/getTreatmentPlan"
    }
}