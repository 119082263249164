import { Route_Url } from "common/constants";
import { isEmpty } from "lodash";

const checkIfVerifiedUser = (userDetail) =>
  userDetail && userDetail?.emailVerified;

const range = (start, end) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

const getUsername = (email) => {
  if (!email) return "";
  return email?.includes("@") ? email?.split("@")[0] : email;
};

const getInitials = (firstName, lastName) => {
  if (firstName?.length === 0 && lastName?.length === 0) return "";
  return firstName?.[0].toUpperCase() + lastName?.[0].toUpperCase();
};

const isValidData = (data) => !isEmpty(data);

const isClient = (user) => user?.role?.toLowerCase() === "client";
const isTherapist = (user) => user?.role?.toLowerCase() === "therapist";

const isClientRoute = (pathName) => pathName?.includes(Route_Url.Client);
const isTherapistRoute = (pathName) => pathName?.includes(Route_Url.Therapist);

const noEmptySpaces = (value) => value.trim() !== "" || "Empty spaces are not allowed";

export {
  checkIfVerifiedUser,
  range,
  getUsername,
  isValidData,
  getInitials,
  isClient,
  isTherapist,
  isClientRoute,
  isTherapistRoute,
  noEmptySpaces
};
