import { Spin, Typography } from "antd";
import { useSelector } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
export default function LoadingIndicator(props) {
  const { value } = useSelector((state) => state.progress);
  const loadingText = (
    <Typography.Title level={4} editable={false} className="pt-3 text-center">
      Loading
    </Typography.Title>
  );

  return (
    // <Spin
    //   size="large"
    //   delay={500}
    //   spinning={value > 0}
    //   tip={loadingText}
    //   className="cursor-pointer"
    //   indicator={<LoadingOutlined className="" style={{ fontSize: 48 }} spin />}
    // >
      props.children
    // </Spin>
  );
}

LoadingIndicator.propTypes = {
  children: PropTypes.node
}
