import { Form, Select, Skeleton, Spin } from "antd";
import { useFetchTreatmentPlan } from "common/hooks/queries";
import EvaluationQuestionsDisplay from "components/assessment/InitialAssessmentDisplay";
import { dispatchNotification } from "helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  submitInitialEvaluationAnswers,
  submitTreatmentPlanAnswers,
} from "services/ApiService";
import "./styles.css";
import TreatmentPlanQuestionsDisplay from "components/assessment/TreatmentPlanQuestionDisplay";

export const TreatmentPlanTab = (props) => {
  const { appointmentId } = props;
  const { data, isLoading } = useFetchTreatmentPlan(appointmentId);
  const { sections } = data?.assessmentQuestions || {};
  const [evaluationForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const formatValues = useCallback((data) => {
    const formatted = [];
    Object.entries(data).map(([key, value]) => {
      let answer = data[key] ? [data[key]] : [];
      if (typeof value === "object" && Array.isArray(value)) {
        answer = data[key];
      }

      formatted.push({
        questionNumber: key,
        answers: answer,
      });
    });
    return formatted;
  }, []);

  const handleFormUpdate = async (values) => {
    setLoading(true);
    const sectionsAndQuestions = sections.map((s) => ({
      id: s.sectionNumber,
      questions: s.questions.map((q) => q.questionNumber),
    }));
    const formattedValues = formatValues(values);
    const payload = [];
    sectionsAndQuestions.forEach((section,index) => {
      const values = formattedValues.filter((f) =>
        section?.questions?.includes(+f.questionNumber)
      );
      payload.push({
        sectionNumber: section?.id,
        response: values,
        finalSubmission: index === sectionsAndQuestions?.length - 1,
      });
    });

    try {
      const response = await submitTreatmentPlanAnswers(payload, appointmentId);
      dispatchNotification("success", `You response has been saved`);
      setLoading(false);
    } catch (error) {
      dispatchNotification(
        "error",
        `Unable to save the response.`,
        "Please try again"
      );
      setLoading(false);
    }
  };

  return (
    <Skeleton loading={isLoading} active>
      <section>
        <section className="pt-[3.375rem]">
          <Spin spinning={loading} tip="Saving Response">
            <TreatmentPlanQuestionsDisplay
              form={evaluationForm}
              sections={sections}
              onSaveEvaluation={handleFormUpdate}
            />
          </Spin>
        </section>
      </section>
    </Skeleton>
  );
};
