import { lazy, Suspense } from "react";
import SuspenseLoader from "components/indicator/SuspenseLoader";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Route_Url, Test_Therapist_Id } from "common/constants";
import RoleRedirect from "components/RoleRedirect";
import Doctor_Dashboard from "components/Dashboard/Doctor_Dashboard/Doctor_Dashboard";
import AppoinmentList from "components/appoinments/appointmentlist/AppoinmentList";
import RouteListenerLayout from "components/auth/RouteWrapper";
import EditAppoinments from "components/appoinments/EditAppointment/EditAppoinments";
import SignUpPage from "components/Signup/index";
import { RouteHistory } from "helper";

// Lazy Loaded Components
const Error = lazy(() => import("./components/pages/login/Error"));
const Patient_Dashboard = lazy(
  () => import("./components/Dashboard/Patient_Dashboard/Patient_Dashboard")
);
const Questionnaire = lazy(() => import("./components/Questionnaire"));
const Login = lazy(() => import("./components/Login"));
const PrivateRoutes = lazy(() => import("components/auth/PrivateRoute"));
const LinkSent = lazy(() => import("components/pages/login/LinkSent"));
const GoogleLoginCallback = lazy(() => import("components/pages/login/GoogleLoginCallback"));

const AddAppoinments = lazy(
  () => import("components/appoinments/AddAppoinments")
);

const Approuter = () => {
  // eslint-disable-next-line no-unused-vars
  // const config = "/react/template"
  return (
    <>
      <BrowserRouter history={RouteHistory}>
        <Routes>
          <Route element={<RouteListenerLayout />}>
            <Route index path="/" element={<RoleRedirect />} />
            <Route path={Route_Url.Login} element={<Login />} />
            <Route path={Route_Url.SignUp} element={<SignUpPage />} />
            <Route
              path={`${Route_Url.AppointmentBooking}/:therapistId`}
              element={<AddAppoinments />}
            />

            <Route path={Route_Url.Questionnaire} element={<Questionnaire />} />

            {/* Ptivate Route */}
            <Route element={<PrivateRoutes />}>
              <Route
                path={Route_Url.PatientDashboard}
                element={<Patient_Dashboard />}
              />

              <Route
                path={Route_Url.DoctorDashboard}
                element={<Doctor_Dashboard />}
              />

              <Route
                path={Route_Url.AppointmentList}
                element={<AppoinmentList />}
              />
              <Route
                path={Route_Url.EditAppointment}
                element={<EditAppoinments />}
              />
            </Route>
          </Route>

          <Route path={Route_Url.EmailVerificaiton} element={<LinkSent />} />
          <Route path={Route_Url.GoogleLoginCallback} element={<GoogleLoginCallback />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default Approuter;
