import { Route_Url } from "common/constants";
import { useActions } from "common/hooks";
import DynamicLoadingIndicator from "components/indicator/AuthLoadingIndicator";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import {
  FIREBASE_ERROR_MSG,
  getFirebaseAuth,
  Storage_Keys,
  StorageHelper
} from "helper";
import { useLayoutEffect, useState } from "react";
import { verifyUser } from "services/ApiService";

export const AuthProvider = ({ children }) => {
  const auth = getFirebaseAuth();
  const [loading, setLoading] = useState(true);
  const { setUser, displayNotification, incrementLoader } = useActions();

  const loadUser = async (isSignInWithLink = false) => {
    try {
      const data = await verifyUser();
      setUser(data);
      if (isSignInWithLink) {
        displayNotification({
          type: "success",
          message: `Welcome  ${data?.name} !!`,
          description: "You have logged in successfully",
        });
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      displayNotification({
        type: "error",
        message: `Error occurred while authenticating !!`,
        description: "Please contact the administrator",
      });
    }
  };
  useLayoutEffect(() => {
    let email = window.localStorage.getItem("emailForSignIn");
    let search = window.location?.search;
    const therapistSearch = search?.split("therapistId=")?.[1];
    const therapistId = therapistSearch?.split("&")?.[0];
    
    const verificationLink = window.location.href;

    if (therapistId) {
    StorageHelper.store(Storage_Keys.TherapistIDForClient, therapistId);
      window.history.pushState(
        null,
        null,
        `${Route_Url.AppointmentBooking}/${therapistId}`
      );
    }


    if (therapistId) {
      window.history.pushState(
        null,
        null,
        `${Route_Url.AppointmentBooking}/${therapistId}`
      );
    }


    if (isSignInWithEmailLink(auth, verificationLink)) {
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }

      signInWithEmailLink(auth, email, verificationLink)
        .then(async function (result) {
          if (!result?.emailVerified) {
            setLoading(false);
            return;
          }
          loadUser(true);
          window.localStorage.removeItem("emailForSignIn");
        })
        .catch(function (error) {
          displayNotification({
            type: "error",
            message: FIREBASE_ERROR_MSG[error?.code]?.message,
            description: FIREBASE_ERROR_MSG[error?.code]?.description,
          });
        });
    }

    auth.onAuthStateChanged((value) => {
      console.log(value);
      if (!value?.emailVerified) {
        setLoading(false);
        return;
      }
      loadUser();
    });
  }, []);

  if (loading) {
    return <DynamicLoadingIndicator text="Checking the credentials ..." />;
  }
  return children;
};
