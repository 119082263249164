import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";
import { DATE_FORMATS } from "common/constants";
import { Minus, Plus } from "feather-icons-react/build/IconComponents";
import { dayjs } from "helper";
import { useMemo, useState } from "react";

export default function DynamicFormField(props) {
  const {
    sequence,
    text,
    isMandatory,
    type,
    options,
    form,
    otherAnswer,
    conditionalOptions,
    sectionId,
    hasDynamicHandling,
    userResponse,
    dependentOn,
    questionNumber,
  } = props;

  const fieldName = `${questionNumber}`;
  const [value, setValue] = useState(null);
  const [otherOptions, setOtherOptions] = useState([])

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  const TYPE = {
    text: "string",
    radio: "",
    date: "date",
  };

  const isOtherSelected = value === "Other";
  const isYesOrNo = options?.length <= 2;

  const handleDecrement = () => {
    const value = +form?.getFieldValue(fieldName);
    if(!value) return;
    form.setFieldValue(fieldName,+form?.getFieldValue(fieldName) - 1)
  }

  const handleIncrement = () => {
    form.setFieldValue(fieldName,+form?.getFieldValue(fieldName) + 1)
  }
  
  

  const handleMultiSelect = () => {
    if(dependentOn) {
      const values  = conditionalOptions[form.getFieldValue(dependentOn)];
      setOtherOptions(values);
      if(otherOptions !== values) {
        form.setFieldValue(fieldName,[]);
      }
    } 
  }
  const selectOptions = useMemo(
    () =>
      options?.length > 0 ? options?.map((option, index) => ({
            label: `${option}`,
            value: option,
          }))
        :  otherOptions?.map((option, index) => ({
          label: `${option}`,
          value: option,
        }))
      ,
    [options, otherOptions, type]
  );
  const SuffixIcons = (
    <div className="flex flex-row gap-4 items-stretch cursor-pointer">
      <Button
        shape="circle"
        size="small"
        onClick={handleIncrement}
        className="flex items-center justify-center "
      >
        <Plus />
      </Button>
      <Button
        shape="circle"
        size="small"
        onClick={handleDecrement}
        className="flex items-center justify-center"
      >
        <Minus />
      </Button>
    </div>
  );
  const DynamicFieldType = {
    text: <Input className="form-control w-full" type="text" placeholder="" />,
    number: (
      <InputNumber
        controls={false}
        addonAfter={SuffixIcons}
        min={0}
        className="form-control w-full p-0 border-none"
        type="text"
        placeholder=""
        required={false}
      />
    ),
    textArea: (
      <Input.TextArea
        className="form-control w-full"
        type="text"
        placeholder=""
      />
    ),
    select: <Select type="text" placeholder="" options={selectOptions} />,
    multiselect: (
      <Select
        type="text"
        placeholder=""
        options={selectOptions}
        onDropdownVisibleChange={handleMultiSelect}
        mode="multiple"
      />
    ),
    radio: (
      <Radio.Group
        onChange={(e) => setValue(e.target.value)}
        className="flex flex-row items-center justify-start flex-wrap gap-2 text-sm text-black font-normal"
      >
        {options.map((option) => (
          <div
            className={`${isYesOrNo ? "basis-auto" : "basis-[24%]"}`}
            key={option}
          >
            <div className="flex flex-row flex-wrap items-start justify-between">
              <Radio value={option} className="pt-1">
                {option}
              </Radio>

              {option === "Other" && (
                <Form.Item
                  name={`${fieldName}-Other`}
                  required={isOtherSelected}
                  rules={[
                    {
                      required: isOtherSelected,
                      message: `Required`,
                    },
                  ]}
                  initialValue={otherAnswer}
                >
                  <Input
                    disabled={!isOtherSelected}
                    className="h-9 px-4 py-2
                       rounded-md text-sm text-black"
                    placeholder="Enter your choice"
                  />
                </Form.Item>
              )}
            </div>
          </div>
        ))}
      </Radio.Group>
    ),
    date: (
      <DatePicker
        className="form-control datetimepicker w-1/4"
        placeholder="Select date"
        format={DATE_FORMATS["MM/DD/YYYY"]}
      />
    ),
  };

  const field = DynamicFieldType[type];

  const formValue = useMemo(() => {
    if (!userResponse?.length && type === "multiselect") return [];
    if (!userResponse?.length) return "";
    if (type === "date" && userResponse?.length > 0) {
      return dayjs(userResponse?.[0]);
    }
    return userResponse?.[0];
  }, [userResponse]);

  return (
    <Form.Item
      name={fieldName}
      key={`${sectionId}-${questionNumber}`}
      required={isMandatory}
      initialValue={formValue}
      rules={[
        {
          required: isMandatory,
          message: `Answer is required`,
        },
      ]}
    >
      {field}
    </Form.Item>
  );
}
