export const StorageHelper = {
    load(key) {
        const stored = window.localStorage.getItem(key);
        return stored == null ? undefined : stored;
    },
    store(key, value) {
        window.localStorage.setItem(key, value);
    },
    modify(key, fn) {
        this.store(key, fn(this.load(key)));
    },
    appendItemToArray: (item, storageID) => {
        this.modify(storageID, (storage = []) => [...storage, item]);
    },
    removeItemFromArray: (item, storageID) => {
        this.modify(storageID, (storage = []) => storage.filter(s => s !== item));
    },
    saveItemToObject: (item, storageID) => {
        this.modify(storageID, (storage = {}) => ({...storage, item}));
    },
    //...
};

export const Storage_Keys = {
    TherapistIDForClient: "therapistIdForClient"
}