import React, { useCallback } from "react";
import { Button, Form } from "antd";
import DynamicFormField from "components/appoinments/DynamicFormField";
import { keys } from "lodash";
import { getQuestionErrors } from "helper";
import { useActions } from "common/hooks";

export default function TreatmentPlanQuestionsDisplay(props) {
  const { sections, form, onSaveEvaluation } = props;
  const { displayNotification } = useActions();

  const handleFormSubmission = () => {
    form.validateFields().then(
      (values) => {
        onSaveEvaluation(values);
      },
      (error) => {
        const errors = getQuestionErrors(error);
        displayNotification({
          type: "error",
          message: `${errors?.join(" , ")} ${errors?.length > 1 ? "are" : "is"} required`,
          description: "Please fill the mandatory fields",
        });
      }
    );
  };

  const getDependentOptions = useCallback(
    (question) => {
      let options = question?.options;
      if (question?.dependentOn) {
        const optionKeys = keys(question?.conditionalOptions);
        //  options = question?.conditionalOptions?.[optionKeys?.[0]];
      }

      return options;
    },
    [form]
  );

  return (
    <section>
      {/* Radio Section */}
      <Form
        layout="vertical"
        form={form}
        preserve
        scrollToFirstError
        validateTrigger="onChange"
      >
        <section>
          {sections.map((section) => (
            <section
              key={section?.sectionNumber}
              className="flex flex-row flex-wrap gap-2"
            >
              <header className="text-lg font-medium basis-full pb-4">
                {section?.name}
              </header>
              {section?.questions.map((question) => (
                <section
                  key={question?.questionNumber}
                  className="basis-full mx-10"
                >
                  <div
                    className="flex flex-col"
                    key={`${section?.sectionNumber}-${question?.questionNumber}`}
                  >
                    <p className="text-base font-normal pb-1.5">
                      {question?.questionNumber}. {question?.text}{" "}
                      {question?.isMandatory && (
                        <span className="text-red-600"> *</span>
                      )}
                    </p>

                    <DynamicFormField
                      {...question}
                      form={form}
                      options={getDependentOptions(question)}
                      sectionId={section?.sectionNumber}
                    />
                  </div>
                </section>
              ))}
            </section>
          ))}
        </section>
        <section className="flex justify-end gap-4 text-base font-medium pt-12">
          <Button
            htmlType="button"
            onClick={() => handleFormSubmission()}
            style={{
              background:
                "linear-gradient(270deg, #2E37A4 44.5%, #4E57CD 100%)",
            }}
            className="rounded-lg min-w-[11rem] h-10 text-white border-none"
          >
            Save
          </Button>
        </section>
      </Form>
    </section>
  );
}
