const getQuestionErrors = (error) => {
  const errorFields = error?.errorFields;
  const errors = [];
  if (Array.isArray(errorFields) && errorFields?.length > 0) {
    errorFields.forEach((error) => {
      errors?.push(`Question ${error?.name?.[0]}`);
    });
  }
  return errors;
};

export { getQuestionErrors } ;
