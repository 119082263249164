import React from "react";
import { Provider } from "react-redux";
import { store } from "../store";
import PropTypes from "prop-types";

export default function ReduxProvider(props) {
  return <Provider store={store}>{props.children}</Provider>;
}

ReduxProvider.propTypes = {
  children: PropTypes.node,
};
